.gallery {
  &__wrapper {
    @apply max-w-6xl border-r border-pageBGDark border-solid;
  }

  &__item {
    @apply cursor-pointer;
  }

  &__primary-image {
    @apply cursor-pointer;
  }

  &__thumb {
    @apply w-40;
  }

  &__thumbnails {
    /* @apply overflow-hidden w-full; */
    @apply flex;
  }
}
