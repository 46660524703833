.cart {
  @apply fixed top-0 right-0 w-full h-screen max-w-3xl z-90 text-pageText bg-pageBG invisible pointer-events-none;
  height: calc(var(--vh, 1vh) * 100);
  will-change: transform;
  transition: visibility 0.2s 0.8s;

  @media (min-width: 769px) {
    @apply border-l;
  }

  &.is-active {
    @apply visible pointer-events-auto;
    transition-delay: 0s;
  }

  &.is-updating {
    @apply cursor-wait !important;
  }

  &--backdrop {
    @apply fixed inset-0 z-80 bg-black bg-opacity-40 pointer-events-none opacity-0;
    transition: opacity 0.15s linear;

    &.is-active {
      @apply pointer-events-auto opacity-100;
      backdrop-filter: blur(6px);
    }
  }

  &--inner {
    @apply flex flex-col relative h-full w-full;
  }

  &--header {
    @apply flex items-center relative px-4 py-3 sm:px-8 sm:py-6 text-sm leading-normal font-semibold uppercase;

    &::after {
      @apply absolute inset-x-0 bottom-0 border-t;
      content: '';
    }
  }

  &--title {
    @apply flex items-center h-12 mr-auto;
  }

  &--count {
    @apply opacity-30 ml-2;
  }

  &--content {
    @apply flex-1 flex flex-col overflow-y-scroll p-4 sm:p-8;
  }

  &--footer {
    @apply relative px-4 pb-4 sm:px-8 sm:pb-8;
  }

  &--subtotal {
    @apply flex justify-between py-4 sm:py-8 px-4 border-t;
  }

  &--message {
    @apply mt-4 text-center text-xs sm:text-sm;
  }

  &--empty {
    @apply my-auto pb-28 text-center;
  }
}

.cart-item {
  @apply flex relative;

  &:not(:last-child) {
    @apply mb-8 pb-8 border-b;
  }

  .is-updating & {
    @apply pointer-events-none;
  }

  &--photo {
    @apply flex-shrink-0 relative m-0 w-1/4 sm:w-1/3;
    max-width: 10rem;
  }

  &--details {
    @apply flex flex-col flex-grow pl-4;
  }

  &--header {
    @apply sm:flex;

    .price {
      @apply ml-auto;

      &--current {
        @apply sm:text-lg sm:leading-normal;
      }
    }
  }

  &--title {
    @apply sm:pr-12;
  }

  &--variant {
    @apply mb-1 leading-relaxed tracking-wider;
  }

  &--name {
    @apply mb-2 sm:mb-4 text-base sm:text-2xl;
  }

  &--link {
    @apply block text-current;

    &::after {
      @apply block absolute inset-0 z-10;
      content: '';
    }
  }

  &--title {
    @apply flex-1 m-0;
  }

  &--price {
    @apply ml-4;
  }

  &--variant {
    @apply text-sm;
  }

  &--tools {
    @apply flex justify-between relative z-10 mt-auto pt-6;
  }
}
