.counter {
  @apply inline-grid h-full;
  grid-template-columns: auto auto auto;

  &--down,
  &--up {
    @apply m-0 p-0 bg-transparent;

    svg {
      @apply block;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    @apply m-0 appearance-none;
  }
  
  &--amount {
    @apply relative overflow-hidden w-12;

    &:focus-within {
      outline: 1px dashed var(--pageText);
      outline-offset: 2px;

      .is-inverted & {
        outline-color: var(--pageBG);
      }
      
      body:not(.is-tabbing) & {
        outline: none;
      }
    }
  }

  &--input {
    @apply flex relative w-full h-full;
    will-change: transform;

    + .counter--input {
      @apply absolute inset-0;
    }
    
    input {
      @apply relative bg-transparent border-0 rounded-none appearance-none p-0 w-full h-full text-center text-current text-base outline-none;
      font-feature-settings: "tnum";
    }
  }
}