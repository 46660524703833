.product-card-minimal {
  @apply flex flex-col relative;

  .price {
    @apply mt-2 inline text-sm;
  }

  &--thumb {
    @apply z-0;
  }

  &--photo {
    @apply relative overflow-hidden h-96;
  }

  &--title {
    @apply m-0 !important text-sm;
  }

  &--link {
    @apply block no-underline text-current;

    &::after {
      @apply block absolute inset-0 z-20;
      content: '';
    }
  }

  &--option {
    @apply relative z-30 mt-4;

    .option {
      @apply m-0 p-0 border-0;

      &--values {
        @apply mx-auto;
      }
    }
  }
}
