.quote {
  @apply p-5;

  &__wrapper {
    @apply container mx-auto my-40;
  }
  p {
    @apply font-extralight text-xl sm:text-3xl lg:text-6xl leading-relaxed sm:leading-relaxed lg:leading-relaxed;
  }
}
