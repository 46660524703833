.sheet-of-paper {
  @apply py-20 px-5;
  &__content {
    @apply max-w-screen-md mx-auto p-10 sm:p-20 bg-white shadow-md;
  }
  /* max-width: 800px;
    margin: auto;
    padding: 100px;
    background: white;
    box-shadow: 1px 3px 13px #0000005c; */
}
