.paragraph {
  @apply py-8 px-5 md:px-0 container mx-auto;

  &__content > * {
    @apply max-w-2xl;
  }

  &.has-block-angles {
    @apply bg-transparent;
  }

  h1,
  .is-h1,
  h2,
  .is-h2,
  h3,
  .is-h3,
  h4,
  .is-h4 {
    @apply max-w-4xl;
  }
  .is-h1xl {
    @apply max-w-none;
  }

  .rc__image {
    @apply flex my-8 max-w-none;
  }
}
