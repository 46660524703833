.brand-profile {
  @apply container px-5 py-24 mx-auto flex flex-col relative;

  .rc {
    @apply mt-8;
    p {
      @apply text-2xl sm:text-3xl leading-normal sm:leading-normal;
    }
  }

  &__bg-image {
    z-index: -1;
  }

  &__container {
    @apply lg:w-10/12 mx-auto flex flex-col sm:flex-row mt-10 p-5 sm:p-8;
    background: rgba(255, 255, 255, 0.5);
  }

  &__designers {
    @apply sm:w-1/3 text-center sm:pr-8 sm:py-8 flex flex-wrap justify-center;
  }

  &__designer {
    @apply w-1/2 sm:w-full mt-6;
  }

  &__designer-name {
    @apply flex flex-col items-center text-center justify-center mt-3;
  }

  &__designer-profile-photo {
    @apply w-32 sm:w-40 h-32 sm:h-40 rounded-full inline-block items-center justify-center filter grayscale;

    img {
      @apply rounded-full;
    }
  }

  &__brand-bio {
    @apply sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-pageBGDark sm:border-t-0 border-t mt-4 pt-4 sm:mt-0;
  }

  &__brand-meta {
    @apply flex flex-wrap justify-items-end mt-6;
  }

  &__brand-meta-item {
    @apply text-sm w-1/2 sm:w-1/3 pr-2;
  }

  &__brand-instagram {
    @apply flex flex-wrap justify-items-end mt-6;
  }

  &__brand-instagram-item {
    @apply text-sm w-full sm:w-1/3 pr-2;
  }

  &__learn-more {
    @apply inline-block text-xl mt-10 border-b mr-10;
    &:hover {
      @apply text-pageTheme;
      &:after {
        content: '⟶';
        @apply ml-8;
      }
    }
    &:after {
      content: '⟶';
      @apply ml-3 transition-all;
    }
  }

  &__instagram {
    @apply h-6;
  }
}
