.image-grid {
  @apply p-4 sm:p-8;

  &--wrapper {
    @apply container mx-auto flex flex-wrap;
  }

  &--content {
    @apply flex w-full mb-10 flex-wrap;
  }

  &--title {
    @apply text-3xl font-medium mb-0 pb-0 max-w-prose md:w-1/3;
  }

  &--caption {
    @apply max-w-prose mt-4 sm:mt-0 sm:pl-5 md:w-2/3;
  }

  &--images {
    @apply flex flex-wrap w-full;
  }

  &--image-block {
    @apply flex flex-wrap w-full sm:w-1/2;
  }

  &--image-small-wrapper {
    @apply md:p-2 p-1 w-1/2;
  }

  &--image-small {
    @apply w-full object-cover object-center block;
  }

  &--image-large-wrapper {
    @apply md:p-2 p-1 w-full;
  }

  &--image-large {
    @apply w-full object-cover object-center block;
  }

  &--image {
    @apply p-1 relative h-full min-h-200 sm:min-h-400 lg:min-h-600;
  }

  &--button {
    @apply mt-4;
  }

  &--details {
    @apply px-8 py-10 h-full relative z-10 w-full bg-opacity-10 md:bg-opacity-25 bg-black text-white md:opacity-0 md:hover:opacity-100;
    transition: opacity 0.45s linear;
  }
}
