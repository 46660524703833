.block-angle-decorator {
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    background: inherit;
    content: '';
    display: block;
    left: 0px;
    position: absolute;
    right: 0px;
    z-index: -1;
    backface-visibility: hidden;
  }
}
