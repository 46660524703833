.brand-cta {
  @apply container px-5 py-24 mx-auto;

  &__container {
    @apply mx-auto flex flex-col sm:flex-row mt-10;
  }

  &__link {
    @apply text-2xl sm:text-5xl mt-5;
  }
  &__link-item {
    @apply border-b;
    &:hover {
      @apply text-pageTheme;
      &:after {
        content: '⟶';
        @apply ml-8;
      }
    }
    &:after {
      content: '⟶';
      @apply ml-3 transition-all;
    }
  }

  &__instagrams {
    @apply flex flex-wrap justify-items-end mt-6;
  }
  &__instagram {
    @apply mr-4;
    &:hover {
      @apply text-pageTheme;
    }
  }
}
