*, ::before, ::after {
  border-color: currentColor;
}

.container {
  width: 100%;
}

@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 940px) {
  .container {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.z-10 {
  z-index: 10;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-5 {
  width: 1.25rem;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-full {
  width: 100%;
}

.max-w-0 {
  max-width: 0rem;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-min {
  max-width: -webkit-min-content;
  max-width: -moz-min-content;
  max-width: min-content;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-xs {
  max-width: 480px;
}

.max-w-screen-sm {
  max-width: 768px;
}

.max-w-screen-md {
  max-width: 940px;
}

.max-w-screen-lg {
  max-width: 1200px;
}

.max-w-screen-xl {
  max-width: 1600px;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-x-4 {
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-xxs {
  font-size: .625rem;
}

.font-medium {
  font-weight: 500;
}

.text-transparent {
  color: transparent;
}

.text-current {
  color: currentColor;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-pageBG {
  color: var(--pageBG);
}

.text-pageBGDark {
  color: var(--pageBGDark);
}

.text-pageText {
  color: var(--pageText);
}

.text-pageTheme {
  color: var(--pageTheme);
}

.text-pagePrimary {
  color: var(--pagePrimary);
}

.text-pageSecondary {
  color: var(--pageSecondary);
}

.text-pageHighlight {
  color: var(--pageHighlight);
}

.text-pageDark {
  color: var(--pageDark);
}

.text-opacity-0 {
  --tw-text-opacity: 0;
}

.text-opacity-5 {
  --tw-text-opacity: 0.05;
}

.text-opacity-10 {
  --tw-text-opacity: 0.1;
}

.text-opacity-20 {
  --tw-text-opacity: 0.2;
}

.text-opacity-25 {
  --tw-text-opacity: 0.25;
}

.text-opacity-30 {
  --tw-text-opacity: 0.3;
}

.text-opacity-40 {
  --tw-text-opacity: 0.4;
}

.text-opacity-50 {
  --tw-text-opacity: 0.5;
}

.text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.text-opacity-70 {
  --tw-text-opacity: 0.7;
}

.text-opacity-75 {
  --tw-text-opacity: 0.75;
}

.text-opacity-80 {
  --tw-text-opacity: 0.8;
}

.text-opacity-90 {
  --tw-text-opacity: 0.9;
}

.text-opacity-95 {
  --tw-text-opacity: 0.95;
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@font-face {
  font-family: 'Century Gothic';

  src: url('/fonts/century-gothic/CenturyGothic.woff') format('woff');

  font-weight: 300;

  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';

  src: url('/fonts/century-gothic/CenturyGothic-Bold.woff') format('woff');

  font-weight: 700;

  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';

  src: url('/fonts/century-gothic/CenturyGothic-Italic.woff') format('woff');

  font-weight: normal;

  font-style: italic;
}

@media (min-width: 480px) {
  .xs\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xs\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xs\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xs\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xs\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xs\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xs\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xs\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xs\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xs\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xs\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xs\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xs\:col-span-full {
    grid-column: 1 / -1;
  }

  .xs\:col-start-1 {
    grid-column-start: 1;
  }

  .xs\:col-start-2 {
    grid-column-start: 2;
  }

  .xs\:col-start-3 {
    grid-column-start: 3;
  }

  .xs\:col-start-4 {
    grid-column-start: 4;
  }

  .xs\:col-start-5 {
    grid-column-start: 5;
  }

  .xs\:col-start-6 {
    grid-column-start: 6;
  }

  .xs\:col-start-7 {
    grid-column-start: 7;
  }

  .xs\:col-start-8 {
    grid-column-start: 8;
  }

  .xs\:col-start-9 {
    grid-column-start: 9;
  }

  .xs\:col-start-10 {
    grid-column-start: 10;
  }

  .xs\:col-start-11 {
    grid-column-start: 11;
  }

  .xs\:col-start-12 {
    grid-column-start: 12;
  }

  .xs\:col-start-13 {
    grid-column-start: 13;
  }

  .xs\:col-start-auto {
    grid-column-start: auto;
  }

  .xs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xs\:grid-cols-none {
    grid-template-columns: none;
  }

  .xs\:self-auto {
    align-self: auto;
  }

  .xs\:self-start {
    align-self: flex-start;
  }

  .xs\:self-end {
    align-self: flex-end;
  }

  .xs\:self-center {
    align-self: center;
  }

  .xs\:self-stretch {
    align-self: stretch;
  }

  .xs\:self-baseline {
    align-self: baseline;
  }

  .xs\:justify-self-auto {
    justify-self: auto;
  }

  .xs\:justify-self-start {
    justify-self: start;
  }

  .xs\:justify-self-end {
    justify-self: end;
  }

  .xs\:justify-self-center {
    justify-self: center;
  }

  .xs\:justify-self-stretch {
    justify-self: stretch;
  }
}

@media (min-width: 768px) {
  .sm\:order-last {
    order: 9999;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-start-3 {
    grid-column-start: 3;
  }

  .sm\:col-start-4 {
    grid-column-start: 4;
  }

  .sm\:col-start-5 {
    grid-column-start: 5;
  }

  .sm\:col-start-6 {
    grid-column-start: 6;
  }

  .sm\:col-start-7 {
    grid-column-start: 7;
  }

  .sm\:col-start-8 {
    grid-column-start: 8;
  }

  .sm\:col-start-9 {
    grid-column-start: 9;
  }

  .sm\:col-start-10 {
    grid-column-start: 10;
  }

  .sm\:col-start-11 {
    grid-column-start: 11;
  }

  .sm\:col-start-12 {
    grid-column-start: 12;
  }

  .sm\:col-start-13 {
    grid-column-start: 13;
  }

  .sm\:col-start-auto {
    grid-column-start: auto;
  }

  .sm\:w-1 {
    width: 0.25rem;
  }

  .sm\:w-2 {
    width: 0.5rem;
  }

  .sm\:w-3 {
    width: 0.75rem;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-5 {
    width: 1.25rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-11 {
    width: 2.75rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:w-14 {
    width: 3.5rem;
  }

  .sm\:w-16 {
    width: 4rem;
  }

  .sm\:w-20 {
    width: 5rem;
  }

  .sm\:w-24 {
    width: 6rem;
  }

  .sm\:w-28 {
    width: 7rem;
  }

  .sm\:w-32 {
    width: 8rem;
  }

  .sm\:w-36 {
    width: 9rem;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-44 {
    width: 11rem;
  }

  .sm\:w-48 {
    width: 12rem;
  }

  .sm\:w-52 {
    width: 13rem;
  }

  .sm\:w-56 {
    width: 14rem;
  }

  .sm\:w-60 {
    width: 15rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-1\.5 {
    width: 0.375rem;
  }

  .sm\:w-2\.5 {
    width: 0.625rem;
  }

  .sm\:w-3\.5 {
    width: 0.875rem;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.333333%;
  }

  .sm\:w-2\/3 {
    width: 66.666667%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-2\/4 {
    width: 50%;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:w-1\/5 {
    width: 20%;
  }

  .sm\:w-2\/5 {
    width: 40%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-1\/6 {
    width: 16.666667%;
  }

  .sm\:w-2\/6 {
    width: 33.333333%;
  }

  .sm\:w-3\/6 {
    width: 50%;
  }

  .sm\:w-4\/6 {
    width: 66.666667%;
  }

  .sm\:w-5\/6 {
    width: 83.333333%;
  }

  .sm\:w-1\/12 {
    width: 8.333333%;
  }

  .sm\:w-2\/12 {
    width: 16.666667%;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-4\/12 {
    width: 33.333333%;
  }

  .sm\:w-5\/12 {
    width: 41.666667%;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }

  .sm\:w-10\/12 {
    width: 83.333333%;
  }

  .sm\:w-11\/12 {
    width: 91.666667%;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .sm\:self-auto {
    align-self: auto;
  }

  .sm\:self-start {
    align-self: flex-start;
  }

  .sm\:self-end {
    align-self: flex-end;
  }

  .sm\:self-center {
    align-self: center;
  }

  .sm\:self-stretch {
    align-self: stretch;
  }

  .sm\:self-baseline {
    align-self: baseline;
  }

  .sm\:justify-self-auto {
    justify-self: auto;
  }

  .sm\:justify-self-start {
    justify-self: start;
  }

  .sm\:justify-self-end {
    justify-self: end;
  }

  .sm\:justify-self-center {
    justify-self: center;
  }

  .sm\:justify-self-stretch {
    justify-self: stretch;
  }

  .sm\:p-8 {
    padding: 2rem;
  }
}

@media (min-width: 940px) {
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-full {
    grid-column: 1 / -1;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:col-start-8 {
    grid-column-start: 8;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:col-start-10 {
    grid-column-start: 10;
  }

  .md\:col-start-11 {
    grid-column-start: 11;
  }

  .md\:col-start-12 {
    grid-column-start: 12;
  }

  .md\:col-start-13 {
    grid-column-start: 13;
  }

  .md\:col-start-auto {
    grid-column-start: auto;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:self-auto {
    align-self: auto;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:self-stretch {
    align-self: stretch;
  }

  .md\:self-baseline {
    align-self: baseline;
  }

  .md\:justify-self-auto {
    justify-self: auto;
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:justify-self-center {
    justify-self: center;
  }

  .md\:justify-self-stretch {
    justify-self: stretch;
  }
}

@media (min-width: 1200px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-11 {
    grid-column-start: 11;
  }

  .lg\:col-start-12 {
    grid-column-start: 12;
  }

  .lg\:col-start-13 {
    grid-column-start: 13;
  }

  .lg\:col-start-auto {
    grid-column-start: auto;
  }

  .lg\:w-1\/3 {
    width: 33.333333%;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .lg\:self-auto {
    align-self: auto;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-stretch {
    align-self: stretch;
  }

  .lg\:self-baseline {
    align-self: baseline;
  }

  .lg\:justify-self-auto {
    justify-self: auto;
  }

  .lg\:justify-self-start {
    justify-self: start;
  }

  .lg\:justify-self-end {
    justify-self: end;
  }

  .lg\:justify-self-center {
    justify-self: center;
  }

  .lg\:justify-self-stretch {
    justify-self: stretch;
  }
}

@media (min-width: 1600px) {
  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:col-start-1 {
    grid-column-start: 1;
  }

  .xl\:col-start-2 {
    grid-column-start: 2;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:col-start-4 {
    grid-column-start: 4;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:col-start-8 {
    grid-column-start: 8;
  }

  .xl\:col-start-9 {
    grid-column-start: 9;
  }

  .xl\:col-start-10 {
    grid-column-start: 10;
  }

  .xl\:col-start-11 {
    grid-column-start: 11;
  }

  .xl\:col-start-12 {
    grid-column-start: 12;
  }

  .xl\:col-start-13 {
    grid-column-start: 13;
  }

  .xl\:col-start-auto {
    grid-column-start: auto;
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-cols-none {
    grid-template-columns: none;
  }

  .xl\:self-auto {
    align-self: auto;
  }

  .xl\:self-start {
    align-self: flex-start;
  }

  .xl\:self-end {
    align-self: flex-end;
  }

  .xl\:self-center {
    align-self: center;
  }

  .xl\:self-stretch {
    align-self: stretch;
  }

  .xl\:self-baseline {
    align-self: baseline;
  }

  .xl\:justify-self-auto {
    justify-self: auto;
  }

  .xl\:justify-self-start {
    justify-self: start;
  }

  .xl\:justify-self-end {
    justify-self: end;
  }

  .xl\:justify-self-center {
    justify-self: center;
  }

  .xl\:justify-self-stretch {
    justify-self: stretch;
  }
}

/* Variables */

:root {
  --black: #000000;
  --white: #ffffff;
  --black-hsl: 0, 0%, 0%;
  --white-hsl: 0, 0%, 100%;
  --pageBG: #eef2ef;
  --pageBGDark: #82939b;
  --pageTheme: #256e37;
  --pageText: #256e37;
  --pagePrimary: #256e37;
  --pageSecondary: #f4c66c;
  --pageHighlight: #f4c66c;
  --pageDark: #e7e7e7;
  --pageBG-hsl: 60, 14%, 95%;
  --pageText-hsl: 0, 0%, 0%;
}

.clean-btn {
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  text-decoration: none;
}

.sr-only {
  position: absolute;
  margin: 0px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
  border-width: 0px;
  padding: 0px;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
}

.is-textured {
  background-image: radial-gradient(var(--pageText) 9%, transparent 9%),
    radial-gradient(var(--pageText) 9%, transparent 9%);
  background-position: 0 0, 8px 8px;
  background-size: 16px 16px;
}

.is-textured-dark {
  background-color: var(--pageText);
  background-image: radial-gradient(var(--pageBG) 9%, transparent 9%),
    radial-gradient(var(--pageBG) 9%, transparent 9%);
  background-position: 0 0, 8px 8px;
  background-size: 16px 16px;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 hsla(var(--pageText-hsl), 0.3);
  }

  70% {
    box-shadow: 0 0 0 8px hsla(var(--pageText-hsl), 0);
  }

  100% {
    box-shadow: 0 0 0 0 hsla(var(--pageText-hsl), 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 hsla(var(--pageText-hsl), 0.3);
  }

  70% {
    box-shadow: 0 0 0 8px hsla(var(--pageText-hsl), 0);
  }

  100% {
    box-shadow: 0 0 0 0 hsla(var(--pageText-hsl), 0);
  }
}

*,
::before,
::after {
  border: 0px solid;
  box-sizing: border-box;
}

::before,
::after {
  vertical-align: inherit;
}

html {
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

figure {
  margin: 0;
}

hr {
  color: inherit;
  height: 0;
  overflow: visible;
}

nav ol,
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

small {
  font-size: 75%;
}

::-moz-selection {
  text-shadow: none;
}

::selection {
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img,
iframe {
  border-style: none;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

button,
input,
select,
textarea {
  margin: 0;
}

button {
  overflow: visible;
  text-transform: none;
  color: inherit;
  background: transparent;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  margin: 0;
  overflow: auto;
  resize: vertical;
  resize: block;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled='true'],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden='false'][hidden] {
  display: inline;
  display: initial;
}

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

html,
  body {
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background-color: var(--pageBG);
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  line-height: 1;
  color: var(--pageText);
  font-size: 16px;
}

@media (min-width: 940px) {

body {
    font-size: 18px
}
  }

p {
  margin: 0px;
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 2rem;
}

strong {
  font-weight: 600;
}

ul {
  margin: 0px;
  padding: 0px;
}

/* Headlines */

h1, .is-h1 {
  margin: 0px;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var(--pagePrimary);
}

@media (min-width: 768px) {
  h1, .is-h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

.is-h1xl {
  margin: 0px;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var(--pagePrimary);
}

@media (min-width: 768px) {
  .is-h1xl {
    font-size: 6rem;
    line-height: 1;
  }
}

h2, .is-h2 {
  margin: 0px;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var(--pagePrimary);
}

@media (min-width: 768px) {
  h2, .is-h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

h3, .is-h3 {
  margin: 0px;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var(--pagePrimary);
}

@media (min-width: 768px) {
  h3, .is-h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

h4, .is-h4 {
  margin: 0px;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var(--pagePrimary);
}

@media (min-width: 768px) {
  h4, .is-h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h5 {
  margin: 0px;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var(--pagePrimary);
}

@media (min-width: 768px) {
  h5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

h6 {
  margin: 0px;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: var(--pagePrimary);
}

/* Links */

a {
  color: currentColor;
  color: var(--pagePrimary);
  text-decoration: none;
}

button {
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  text-decoration: none;
}

/* Images */

figure {
  position: relative;
  margin: 0px;
}

/* Misc. */

hr {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 4rem;
  margin-bottom: 4rem;
  height: 1px;
  border-width: 0px;
  background-color: var(--pageText);
}

::-moz-selection {
  background: var(--pageText);
  color: var(--pageBG);
}

::selection {
  background: var(--pageText);
  color: var(--pageBG);
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

/* Focus Adjust */

a:focus, button:focus, input:focus, textarea:focus, select:focus, details:focus {
    outline: 1px dashed var(--pageText);
    outline-offset: 2px;
  }

.is-inverted a:focus, .is-inverted button:focus, .is-inverted input:focus, .is-inverted textarea:focus, .is-inverted select:focus, .is-inverted details:focus {
      outline-color: var(--pageBG);
    }

body:not(.is-tabbing) a:focus, body:not(.is-tabbing) button:focus, body:not(.is-tabbing) input:focus, body:not(.is-tabbing) textarea:focus, body:not(.is-tabbing) select:focus, body:not(.is-tabbing) details:focus {
      outline: none;
    }

p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

.rc ul {
    padding-left: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    line-height: 1.5;
  }

.rc ul:not(:last-child) {
      margin-bottom: 1.5rem;
    }

.rc ul li {
      margin-bottom: 0.25rem;
    }

.rc ul li::before {
        position: relative;
        margin-left: -1rem;
        display: inline-block;
        width: 1rem;
        list-style-type: disc;
      }

.rc ol {
    padding-left: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    line-height: 1.5;
  }

.rc ol:not(:last-child) {
      margin-bottom: 1.5rem;
    }

.rc ol li {
      margin-bottom: 0.25rem;
    }

h1:not(:last-child), .is-h1:not(:last-child) {
    margin-bottom: 2rem;
  }

h1.has-btn, .is-h1.has-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start
  }

@media (min-width: 768px) {
    h1.has-btn, .is-h1.has-btn {
      flex-direction: row;
      align-items: center;
    }
  }

h1.has-btn .btn, .is-h1.has-btn .btn {
      margin-top: 1rem;
    }

@media (min-width: 768px) {
      h1.has-btn .btn, .is-h1.has-btn .btn {
        margin-top: 0px;
        margin-left: 1rem;
      }
    }

.hero h1, .hero .is-h1 {
    font-size: clamp(2.25rem, calc(1rem + 3.5vw), 5rem);
  }

h2:not(:last-child), .is-h2:not(:last-child) {
    margin-bottom: 1rem;
  }

h3:not(:last-child), .is-h3:not(:last-child) {
    margin-bottom: 1rem;
  }

h4:not(:last-child), .is-h4:not(:last-child) {
    margin-bottom: 1.5rem;
  }

.rc a:not(.btn):hover {
      opacity: 0.4;
    }

.rc a:not(.btn) {
      background-image: linear-gradient(var(--pageText), var(--pageText));
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
    }

.rc figure:not(:last-child) {
      margin-bottom: 1.5rem;
    }

hr:last-child {
    margin-bottom: 0px;
  }

.btn {
  position: relative;
  display: inline-block;
  border-width: 1px;
  background-color: transparent;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
  text-decoration: none;
}

.btn.is-block {
    display: block;
    width: 100%;
  }

.btn.is-loading {
    cursor: wait !important;
  }

.btn.is-disabled {
    pointer-events: none;
    cursor: default;
  }

/* default: hover */

.btn:not(.is-primary):not(.is-white):not(.is-disabled):not(.is-soldout).is-active, .btn:not(.is-primary):not(.is-white):not(.is-disabled):not(.is-soldout):hover {
      border-color: var(--pageTheme);
      background-color: var(--pageTheme);
      color: var(--pageBG);
    }

/* default: inverted */

.btn.is-inverted {
    border-color: var(--pageBG);
    background-color: var(--pageTheme);
    color: var(--pageBG);
  }

.btn.is-inverted:not(.is-primary):not(.is-white):not(.is-disabled):not(.is-soldout).is-active, .btn.is-inverted:not(.is-primary):not(.is-white):not(.is-disabled):not(.is-soldout):hover {
        border-color: var(--pageBG);
        background-color: var(--pageBG);
        color: var(--pageTheme);
      }

/* default: disabled */

.btn.is-disabled:not(.is-primary), .btn.is-soldout {
    background-color: var(--pageBG);
    opacity: 0.2;
  }

/* primary style button */

.btn.is-primary {
    border-color: var(--pageTheme);
    background-color: var(--pageTheme);
    color: var(--pageBG);
  }

/* primary: inverted */

.btn.is-primary.is-inverted {
      border-color: var(--pageBG);
      background-color: var(--pageBG);
      color: var(--pageTheme);
    }

/* white style button */

.btn.is-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

/* text-based button */

.btn.is-text {
    border-color: transparent;
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
  }

.btn.is-text.is-disabled, .btn.is-text:not(.is-disabled):hover {
      opacity: 0.6;
    }

/* large sized button */

.btn.is-large {
    margin-top: 1.25rem;
    padding: 1.25rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }

.form {
  position: relative;
}

.form--error {
    /* Error Message styles */
    border-width: 1px;
    background-color: var(--pageBG);
    padding: 1rem;
    text-align: center
  }

@media (min-width: 940px) {
    .form--error {
      padding: 1rem;
    }
  }

.form--error-reset {
      margin-top: 0.5rem;
    }

.form--success {
    /* Success Message styles */
    border-width: 1px;
    background-color: var(--pageBG);
    padding: 1rem;
    text-align: center;
  }

@media (min-width: 940px) {
    .form--success {
      padding: 1rem;
    }
  }

.control {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--pageBG);
  text-align: left;
}

.control--group {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 5px;
    row-gap: 5px
  }

@media (min-width: 768px) {
    .control--group {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

@media (min-width: 768px) {
      .control--group.is-inline {
        display: flex;
      }
    }

.control--group.is-inline .control {
        margin: 0px;
        border-bottom-width: 1px;
        background-color: var(--pageBG);
      }

@media (min-width: 768px) {
        .control--group.is-inline .control {
          flex: 1 1;
        }
      }

@media (min-width: 768px) {
        .control--group.is-inline .btn {
          margin-left: 1rem;
          width: auto;
        }
      }

.control--group.is-clean {
      margin-bottom: 1rem
    }

@media (min-width: 768px) {
      .control--group.is-clean {
        border-bottom-width: 1px;
      }
    }

@media (min-width: 768px) {
        .control--group.is-clean .control {
          border-width: 0px;
        }
      }

@media (min-width: 768px) {
          .control--group.is-clean .control--label, .control--group.is-clean .control input {
            padding-left: 0px;
            padding-right: 0px;
          }
        }

@media (min-width: 768px) {
          .control--group.is-clean .control--error {
            left: 0px;
            transform: none;
          }
        }

@media (min-width: 768px) {
        .control--group.is-clean .btn {
          border-width: 0px;
          background-color: transparent;
          padding-left: 0px;
          padding-right: 0px;
          color: var(--pageText);
        }
      }

.control--label {
    position: absolute;
    top: 50%;
    left: 0px;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-translate-y: -50%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

.is-filled .control--label {
      display: none;
    }

.control--label.for-textarea {
      top: 0px;
      transform: none;
    }

.control--label.for-checkbox {
      position: relative;
      top: auto;
      left: auto;
      margin-left: auto;
      margin-right: auto;
      display: inline-flex;
      transform: none;
      cursor: pointer;
      padding: 0px
    }

@media (min-width: 768px) {
      .control--label.for-checkbox {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

.control--label.for-checkbox svg {
        pointer-events: none;
        margin-right: 1rem;
        display: block;
        flex: none;
        border-width: 1px;
        border-color: currentColor;
        padding: 0.25rem;
        transition-property: background-color, border-color, color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        width: 20px;
        height: 20px;
        transition-delay: 0.15s;
      }

input:checked + .control--label.for-checkbox svg {
          background-color: var(--pageText);
          transition-delay: 0s;
        }

.control--label.for-checkbox svg path {
          stroke: currentColor;
          color: var(--pageBG);
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
          stroke-dashoffset: 120px;
          stroke-dasharray: 120px;
          transition-delay: 0s;
        }

input:checked + .control--label.for-checkbox svg path {
            stroke-dashoffset: 0;
            transition-delay: 0.15s;
          }

.control--label.for-checkbox .rc {
        margin: 0px;
        display: flex;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        flex-direction: column;
        justify-content: center;
      }

.control--label.for-checkbox .rc p {
          font-size: 0.75rem;
          line-height: 1rem;
        }

.control--error {
    position: absolute;
    bottom: 100%;
    left: 50%;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-translate-x: -50%;
    --tw-translate-y: 50%;
    border-radius: 9999px;
    background-color: var(--pageText);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: var(--pageBG);
  }

.control--pot {
    display: none;
  }

.control input[type='text'], .control input[type='email'], .control input[type='number'], .control input[type='password'], .control textarea {
    position: relative;
    height: 100%;
    width: 100%;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 0px;
    border-width: 1px;
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    line-height: 1;
    color: var(--pageText);
  }

.is-inline .control input[type='text'], .is-inline .control input[type='email'], .is-inline .control input[type='number'], .is-inline .control input[type='password'], .is-inline .control textarea {
      border-width: 0px;
    }

.control input[type='text']:-webkit-autofill, .control input[type='text']:-webkit-autofill:hover, .control input[type='text']:-webkit-autofill:focus, .control input[type='email']:-webkit-autofill, .control input[type='email']:-webkit-autofill:hover, .control input[type='email']:-webkit-autofill:focus, .control input[type='number']:-webkit-autofill, .control input[type='number']:-webkit-autofill:hover, .control input[type='number']:-webkit-autofill:focus, .control input[type='password']:-webkit-autofill, .control input[type='password']:-webkit-autofill:hover, .control input[type='password']:-webkit-autofill:focus, .control textarea:-webkit-autofill, .control textarea:-webkit-autofill:hover, .control textarea:-webkit-autofill:focus {
      background-color: transparent;
      color: var(--pageText);
    }

.control input[type='text']:-webkit-autofill,
    .control input[type='text']:-webkit-autofill:hover,
    .control input[type='text']:-webkit-autofill:focus,
    .control input[type='email']:-webkit-autofill,
    .control input[type='email']:-webkit-autofill:hover,
    .control input[type='email']:-webkit-autofill:focus,
    .control input[type='number']:-webkit-autofill,
    .control input[type='number']:-webkit-autofill:hover,
    .control input[type='number']:-webkit-autofill:focus,
    .control input[type='password']:-webkit-autofill,
    .control input[type='password']:-webkit-autofill:hover,
    .control input[type='password']:-webkit-autofill:focus,
    .control textarea:-webkit-autofill,
    .control textarea:-webkit-autofill:hover,
    .control textarea:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--pageText);
      -webkit-box-shadow: 0 0 0 100em var(--pageBG) inset;
    }

.control input[type='checkbox'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    position: absolute;
    margin: 0px;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
    border-width: 0px;
    padding: 0px;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
  }

.control input[type='checkbox']:focus + label {
        outline: 1px dashed var(--pageText);
        outline-offset: 2px;
      }

.is-inverted .control input[type='checkbox']:focus + label {
          outline-color: var(--pageBG);
        }

body:not(.is-tabbing) .control input[type='checkbox']:focus + label {
          outline: none;
        }

.control textarea {
    max-width: 100%;
    min-height: 5rem;
  }

.control.has-error input,
    .control.has-error textarea {
      /* Form Control Error styles */
    }

::-webkit-input-placeholder {
  color: var(--pageText);
}

::-moz-placeholder {
  color: var(--pageText);
}

:-ms-input-placeholder {
  color: var(--pageText);
}

:-moz-placeholder {
  color: var(--pageText);
}

.label {
  display: inline-flex;
  align-items: center;
  background-color: var(--pageText);
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0em;
  color: var(--pageBG);
}

.label::before {
    margin-right: 0.5rem;
    display: inline-block;
    border-radius: 9999px;
    background-color: var(--pageBG);
    width: 10px;
    height: 10px;
    content: "";
  }

.label.is-secondary {
    border-color: var(--pageText);
    background-color: transparent;
    color: var(--pageText);
  }

.label.is-secondary::before {
      background-color: var(--pageText);
      -webkit-animation: pulse 2s infinite;
              animation: pulse 2s infinite;
    }

.ar {
  position: relative;
  overflow: hidden;
}

.ar.has-fill {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

.ar img {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    display: block;
    opacity: 0;
    width: calc(100% + 1px); /* helps with half pixel renderings */
    height: calc(100% + 1px); /* helps with half pixel renderings */
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.4s linear;
  }

.ar img.is-loaded {
      opacity: 1;
    }

.ar--placeholder {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    filter: grayscale(1);
    transform: scale(1.15);
    transition: opacity 0s 0.4s linear;
  }

.ar--placeholder.is-loaded {
      opacity: 0;
    }

.ar--placeholder::after {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 10;
      -webkit-backdrop-filter: blur(1rem) contrast(1.5);
              backdrop-filter: blur(1rem) contrast(1.5);
      content: '';
    }

.ar--placeholder img {
      opacity: 1;
    }

.video-loop {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.video-loop iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100vh;
    width: 100vw;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
  }

html.is-loading, html.is-loading * {
    cursor: wait !important;
  }

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
}

.section {
  position: relative;
  padding: 1rem
}

@media (min-width: 768px) {
  .section {
    padding: 2rem;
  }
}

.section--content {
    margin-left: auto;
    margin-right: auto;
  }

.section-title {
  width: 100%;
}

@media (min-width: 480px) {
  .section-title {
    max-width: 480px;
  }
}

@media (min-width: 768px) {
  .section-title {
    max-width: 768px;
  }
}

@media (min-width: 940px) {
  .section-title {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .section-title {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .section-title {
    max-width: 1600px;
  }
}

.section-title {
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.accordion-group:not(:last-child) {
    margin-bottom: 3rem;
  }

.accordion {
  border-top-width: 1px;
}

.accordion--toggle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    line-height: 1.375;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
  }

.accordion--icon {
    position: relative;
    margin-left: 2rem;
    display: block;
    height: 0.75rem;
    width: 0.75rem;
    flex-shrink: 0;
    transition: transform .3s cubic-bezier(0.22, 1, 0.36, 1);
  }

.accordion--icon::before, .accordion--icon::after {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 50%;
      display: block;
      height: 1px;
      width: 100%;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      --tw-translate-y: -50%;
      background-color: currentColor;
    }

.accordion--icon::before,
    .accordion--icon::after {
      content: "";
    }

.accordion--icon::after {
      --tw-rotate: 90deg;
    }

.is-open .accordion--icon {
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      --tw-rotate: 45deg;
    }

.accordion--content {
    overflow: hidden;
  }

.accordion--inner {
    margin: 0px;
    max-width: 42rem;
    padding-bottom: 3rem;
  }

.carousel {
  position: relative;
  width: 100%;
}

.carousel--slides {
    position: relative;
    display: flex;
    overflow: hidden;
    touch-action: pan-y;
    will-change: transform;
  }

.has-drag .carousel--slides {
      cursor: move;
      cursor: -webkit-grab;
      cursor: grab;
    }

.has-drag .carousel--slides:active {
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }

.carousel--slide {
    position: relative;
    min-height: 100vh;
    min-width: 100%;
    flex-grow: 0;
    place-content: center;
    align-self: center;
    overflow: hidden;
  }

.carousel--thumbs {
    position: absolute;
    top: 0px;
    margin: 0.75rem;
    display: flex;
    width: 5rem;
    flex-direction: column;
  }

.carousel--thumbs button {
      margin: 0.25rem;
    }

.carousel--thumbs button.is-active {
        /* active state styles */
      }

.carousel--hud {
    pointer-events: none;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-translate-y: 50%;
    justify-content: center;
  }

.carousel--nav {
    pointer-events: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 9999px;
    border-width: 1px;
    background-color: var(--pageBG);
    padding: 0.25rem;
  }

.carousel--prev, .carousel--next {
    height: 2rem;
    width: 2rem;
    border-radius: 9999px;
    background-color: transparent;
    padding: 0.5rem;
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 300ms;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
  }

.carousel--prev:hover, .carousel--next:hover {
      background: hsla(var(--pageTheme-hsl), 0.2);
    }

.carousel--prev svg, .carousel--next svg {
      display: block;
    }

.carousel--prev {
    transform: scaleX(-1);
  }

.carousel--status {
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: center;
  }

.carousel--counter {
    position: relative;
    display: grid;
    height: 2rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1px;
    gap: 1px;
    border-radius: 9999px;
    background-color: var(--pageTheme);
    color: var(--pageBG);
  }

.carousel--dots + .carousel--counter {
      margin-right: 0.25rem;
      margin-left: 0.5rem;
    }

.carousel--counter::after {
      position: absolute;
      top: 0.75rem;
      bottom: 0.75rem;
      left: 50%;
      width: 1px;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      --tw-translate-x: -50%;
      background-color: var(--pageBG);
      opacity: 0.3;
      content: '';
    }

.carousel--counter-item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
  }

.carousel--counter-item.is-current {
      padding-left: 0.75rem;
    }

.carousel--counter-item.is-total {
      padding-right: 0.75rem;
    }

.carousel--counter-item .counter-flipper {
      position: relative;
      overflow: hidden;
    }

.carousel--counter-item .counter-flipper span {
        display: block;
        font-feature-settings: 'tnum';
        will-change: transform;
      }

.carousel--counter-item .counter-flipper span + span {
          position: absolute;
          left: 0px;
          right: 0px;
          top: 0px;
        }

.carousel--dots {
    position: relative;
    display: flex;
    height: 2rem;
    align-items: center;
  }

.carousel--dots button {
      background-color: transparent;
      padding: 0.25rem;
      cursor: pointer;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
      text-decoration: none;
    }

.carousel--dots button::before {
        position: relative;
        display: block;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 9999px;
        background-color: var(--pageTheme);
        opacity: 0.3;
        transition: opacity 0.1s linear,
          transform 0.4s cubic-bezier(0.22, 1, 0.36, 1);
        content: '';
      }

.carousel--dots button:hover::before {
          opacity: 1;
        }

/* active state styles */

.carousel--dots button.is-active::before {
          opacity: 1;
          transform: scale(1.5);
        }

.inline-gallery--photo {
  position: relative;
  width: 100%;
  background-color: var(--pageTheme);
}

.inline-gallery--photo:not(:first-child) {
    margin-top: 2.5rem;
  }

.counter {
  display: inline-grid;
  height: 100%;
  grid-template-columns: auto auto auto;
}

.counter--down, .counter--up {
    margin: 0px;
    background-color: transparent;
    padding: 0px;
  }

.counter--down svg, .counter--up svg {
      display: block;
    }

.counter input::-webkit-outer-spin-button, .counter input::-webkit-inner-spin-button {
    margin: 0px;
    -webkit-appearance: none;
            appearance: none;
  }

.counter--amount {
    position: relative;
    width: 3rem;
    overflow: hidden;
  }

.counter--amount:focus-within {
      outline: 1px dashed var(--pageText);
      outline-offset: 2px;
    }

.is-inverted .counter--amount:focus-within {
        outline-color: var(--pageBG);
      }

body:not(.is-tabbing) .counter--amount:focus-within {
        outline: none;
      }

.counter--input {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    will-change: transform;
  }

.counter--input + .counter--input {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

.counter--input input {
      position: relative;
      height: 100%;
      width: 100%;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      border-radius: 0px;
      border-width: 0px;
      background-color: transparent;
      padding: 0px;
      text-align: center;
      font-size: 1rem;
      line-height: 1.5rem;
      color: currentColor;
      outline: 2px solid transparent;
      outline-offset: 2px;
      font-feature-settings: "tnum";
    }

.cursor {
  pointer-events: none;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 0px;
  width: 0px;
  z-index: 120;
}

.cursor--content {
    position: absolute;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
  }

/* Simple dot cursor */

.cursor span {
    position: relative;
    display: block;
    height: 3rem;
    width: 3rem;
  }

.cursor span::before {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      border-radius: 9999px;
      border-width: 1px;
      transform: scale(0);
      will-change: transform;
      transition: transform .5s .1s cubic-bezier(0.22, 1, 0.36, 1);
      content: "";
    }

.cursor span::after {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      border-radius: 9999px;
      background-color: var(--pageText);
      will-change: transform;
      transform: scale(.625);
      transition: transform .5s .1s cubic-bezier(0.22, 1, 0.36, 1);
      content: "";
    }

.cursor.is-hovering span::before {
        transform: scale(1);
      }

.cursor.is-hovering span::after {
        transform: scale(.125);
      }

.drawer {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 90;
  width: 100%;
  max-width: 42rem;
  background-color: var(--pageText);
  color: var(--pageBG);
}

.drawer--backdrop {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 80;
    display: block;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.8;
  }

.drawer--inner {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

.drawer--header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
  }

.drawer--close {
    /* Custom Close Button styles */
  }

.drawer--title {
    margin-right: auto;
  }

.drawer--content {
    flex: 1 1;
    overflow-y: scroll;
    padding: 1rem;
  }

.dropdown {
  position: relative;
  z-index: 10;
}

@media (min-width: 768px) {

.dropdown.is-open {

      z-index: 30
  }
      .dropdown.is-open .dropdown--content {
        pointer-events: auto;
        visibility: visible;
        opacity: 1;
      }
    }

/* (you should mostly offload these to the general header links/button styles) */

.dropdown--toggle {
  }

/* Customize dropdown navigation buttons: */

.dropdown--icon {
  }

.dropdown--content {
    position: relative;
    height: 0px;
    overflow-y: hidden;
  }

@media (min-width: 768px) {

.dropdown--content {
      height: auto !important; /* override mobile accordion styles */ pointer-events: none; visibility: hidden; position: absolute; top: 100%; overflow-y: visible; white-space: nowrap; opacity: 0; transition-property: all; transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); transition-duration: 150ms
  }
    }

.dropdown--nav {
    position: relative;
    margin-top: 0.75rem;
    display: block;
  }

[data-marqy] {
  position: relative;
  overflow: hidden;
  border-top-width: 5px;
  border-bottom-width: 1px;
  border-color: var(--pageBGDark);
}

[data-marqy] * {
    white-space: nowrap;
  }

[data-marqy-inner] {
  display: flex;
}

[data-direction='right'] [data-marqy-inner] {
    justify-content: flex-end;
  }

@media (prefers-reduced-motion) {

[data-marqy-inner] {
    overflow-x: scroll
}
  }

[data-marqy-content] {
  display: flex;
  flex: 1 0 auto;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  will-change: transform;
}

[data-direction='left'] [data-marqy-content] {
    -webkit-animation-name: marqueeL;
            animation-name: marqueeL;
  }

[data-direction='right'] [data-marqy-content] {
    -webkit-animation-name: marqueeR;
            animation-name: marqueeR;
  }

[data-pause-on-hover]:hover [data-marqy-content] {
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }

@media (prefers-reduced-motion) {

[data-marqy-content] {
    -webkit-animation: none !important;
            animation: none !important
}
  }

[data-marqy-item] {
  flex: 1 0 auto;
}

@-webkit-keyframes marqueeL {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes marqueeL {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes marqueeR {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes marqueeR {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.marquee--item {
    display: flex;
  }

.marquee--text {
    display: block;
    padding: 2rem;
    font-size: 3rem;
    line-height: 1;
    line-height: 1;
  }

.marquee--photo {
    position: relative;
    width: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem
  }

@media (min-width: 768px) {
    .marquee--photo {
      width: 12rem;
      padding-left: 3rem;
      padding-right: 3rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

html:not([data-theme='dark']) .marquee--photo img {
        filter: invert(1);
      }

.marquee--product {
    margin-top: -1px;
    margin-bottom: -1px;
    display: flex;
    flex-direction: column;
    width: calc(80vw - 2rem);
    max-width: 28rem;
  }

.marquee--product * {
      white-space: normal;
    }

.marquee--product .product-card {
      flex: 1 1;
      border-right-width: 1px;
      border-left-width: 1px;
      border-color: var(--pageBGDark);
    }

.lg-content {
  top: 0 !important;
}

.cookie-bar {
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 90;
  width: 100%;
  max-width: 48rem;
  padding: 1rem;
}

.cookie-bar--content {
    display: grid;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    border-radius: 0.5rem;
    border-width: 1px;
    background-color: var(--pageText);
    padding: 1.5rem;
    padding-bottom: 1rem;
    color: var(--pageBG);
  }

@media (min-width: 768px) {
    .cookie-bar--content {
      display: flex;
      padding: 1.5rem;
    }
  }

.cookie-bar--message {
    flex: 1 1;
    text-align: center
  }

@media (min-width: 768px) {
    .cookie-bar--message {
      padding-right: 2rem;
      text-align: left;
    }
  }

.cookie-bar--message p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      line-height: 1.5;
    }

.cookie-bar--message p br {
        display: none;
      }

@media (min-width: 768px) {
        .cookie-bar--message p br {
          display: block;
        }
      }

.cookie-bar--actions {
    display: grid;
    flex-shrink: 0;
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

@media (min-width: 768px) {
    .cookie-bar--actions {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

.cookie-bar--actions button {
      order: -9999;
    }

@media (min-width: 768px) {
      .cookie-bar--actions button {
        order: 0;
      }
    }

.promo-bar {
  position: relative;
  z-index: 60;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: var(--pageBGDark);
  text-align: center;
  color: var(--pageBG);
  scroll-behavior: smooth;
}

.promo-bar--content {
    display: inline-flex;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }

.promo-bar--link {
    display: block;
  }

.header {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 50;
  border-color: var(--pagePrimary);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}

.header.is-overlay {
    height: 0px;
  }

.header--outer {
    position: relative;
  }

.header--inner {
    position: relative;
    z-index: 20;
  }

/* background: linear-gradient(360deg, #efc46c00, #a10ba338); */

.is-overlay .header--inner {
      background-color: transparent;
      transition-property: background-color, border-color, color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      transition-duration: 300ms;
    }

.has-bg .header--inner {
      /* background: linear-gradient(360deg, #efc46c00, #a10ba338); */
    }

.is-white .header--inner {
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      transition-delay: 75ms;
    }

.header--content {
    position: relative;
    z-index: 30;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.header--border {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 20;
    border-top-width: 1px;
    transition: opacity 0s 0.6s linear;
  }

.is-white .header--border {
      --tw-border-opacity: 1;
      border-color: rgba(255, 255, 255, var(--tw-border-opacity));
      transition-duration: 0.2s;
      transition-delay: 0s;
      /* &:not(.is-hidden) {
        transition-delay: 0s;
      } */
    }

.header--border.is-hidden {
      opacity: 0;
      transition-delay: 0s;
    }

.header--observer {
    position: absolute;
    top: 2rem;
  }

.header--logo {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

.header--logo-link {
    display: block;
  }

.header--logo-link svg {
      display: block;
      height: 4rem;
      width: auto;
      fill: currentColor;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      transition-duration: 300ms;
    }

.has-bg .header--logo-link svg {
        height: 2.5rem;
      }

.header .menu-toggle {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 60;
    background-color: transparent
  }

@media (min-width: 768px) {
    .header .menu-toggle {
      display: none;
    }
  }

.header .menu-toggle {
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
  }

.is-overlay:not(.has-bg) .header .menu-toggle {
      transition-property: background-color, border-color, color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      transition-duration: 300ms;
    }

.header .menu-toggle.is-open {
      color: var(--pageText);
    }

.header .is-open .hamburger::before, .header .is-open .hamburger::after {
        opacity: 0;
      }

.header .is-open .hamburger::before,
      .header .is-open .hamburger::after {
        transition: opacity 0s 0.2s linear, transform 0.2s ease-out;
      }

.header .is-open .hamburger::before {
        transform: translateY(5px);
      }

.header .is-open .hamburger::after {
        transform: translateY(-5px);
      }

.header .is-open .hamburger--icon {
        border-color: transparent;
      }

.header .is-open .hamburger--icon::before, .header .is-open .hamburger--icon::after {
          opacity: 1;
        }

.header .is-open .hamburger--icon::before,
        .header .is-open .hamburger--icon::after {
          transition: transform 0.2s 0.2s ease-out;
        }

.header .is-open .hamburger--icon::before {
          transform: rotate(45deg);
        }

.header .is-open .hamburger--icon::after {
          transform: rotate(-45deg);
        }

.header .hamburger {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 1.75rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.header .hamburger::before, .header .hamburger::after {
      position: relative;
      display: block;
      height: 0px;
      width: 100%;
      border-top-width: 1px;
      border-color: var(--pageText);
    }

.header .hamburger::before,
    .header .hamburger::after {
      content: '';
      margin: 6px 0;
      transition: opacity 0s 0.2s linear, transform 0.2s 0.2s ease-out;
    }

.is-open .header .hamburger::before, .is-open .header .hamburger::after {
        opacity: 0;
        transition: opacity 0s 0.2s linear, transform 0.2s ease-out;
      }

.is-open .header .hamburger::before {
        transform: translateY(5px);
      }

.is-open .header .hamburger::after {
        transform: translateY(-5px);
      }

.header .hamburger--icon {
      position: relative;
      display: block;
      height: 0px;
      width: 100%;
      border-top-width: 1px;
      border-color: var(--pageText);
      transition: border 0s 0.2s linear;
    }

.is-open .header .hamburger--icon {
        border-color: transparent;
      }

.header .hamburger--icon::before, .header .hamburger--icon::after {
        position: absolute;
        display: block;
        height: 0px;
        width: 100%;
        border-top-width: 1px;
        opacity: 0;
      }

.header .hamburger--icon::before,
      .header .hamburger--icon::after {
        content: '';
        top: calc(50% - 0.5px);
        transition: opacity 0s 0.2s, transform 0.2s ease-out;
      }

.is-open .header .hamburger--icon::before, .is-open .header .hamburger--icon::after {
          opacity: 1;
          transition: transform 0.2s 0.2s ease-out;
        }

.is-open .header .hamburger--icon::before {
          transform: rotate(45deg);
        }

.is-open .header .hamburger--icon::after {
          transform: rotate(-45deg);
        }

.header .cart-toggle {
    position: relative;
    margin-right: -0.5rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    font-weight: 700
  }

@media (min-width: 768px) {
    .header .cart-toggle {
      margin-right: 0.25rem;
      padding: 0.5rem;
    }
  }

.header .cart-toggle {
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
  }

.header .cart-toggle--count {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

.header .main-navigation--desktop {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      display: none;
      align-items: stretch;
      justify-content: space-between
    }

@media (min-width: 768px) {
      .header .main-navigation--desktop {
        display: flex;
      }
    }

.header .main-navigation--desktop .menu-left, .header .main-navigation--desktop .menu-right {
        display: flex;
        align-items: center;
      }

.header .main-navigation--desktop .menu-right {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }

.header .main-navigation--desktop ul {
        display: flex;
      }

.header .main-navigation--desktop ul li {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

.header .main-navigation--desktop ul button {
          background-color: transparent;
          cursor: pointer;
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
          text-decoration: none;
          font: inherit;
        }

.header .main-navigation--desktop ul a, .header .main-navigation--desktop ul button {
          display: block;
          padding: 0.5rem;
          font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
          font-weight: 700;
          text-transform: uppercase;
        }

.header .main-navigation--mobile {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end
    }

@media (min-width: 768px) {
      .header .main-navigation--mobile {
        display: none;
      }
    }

.header .main-navigation--mobile .menu-mobile {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 50;
        display: flex;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        background-color: var(--pageBG);
        color: var(--pageText);
        height: calc(var(--vh, 1vh) * 100);
        max-width: 420px;
        will-change: transform;
      }

@media (min-width: 421px) {

.header .main-navigation--mobile .menu-mobile {
          border-right-width: 1px
      }
        }

.header .main-navigation--mobile .menu-mobile--backdrop {
          pointer-events: none;
          position: fixed;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          z-index: 40;
          --tw-bg-opacity: 1;
          background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
          --tw-bg-opacity: 0.4;
          opacity: 0;
          transition: opacity 0.15s linear;
        }

.header .main-navigation--mobile .menu-mobile--backdrop.is-active {
            pointer-events: auto;
            opacity: 1;
            -webkit-backdrop-filter: blur(6px);
                    backdrop-filter: blur(6px);
          }

.header .main-navigation--mobile .menu-mobile--inner {
          display: flex;
          flex: 1 1;
          flex-direction: column;
          overflow-y: scroll;
          background-color: var(--pageBG);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 1rem;
          padding-top: calc(var(--headerHeight, 10rem) + 1.5rem);
        }

.header .main-navigation--mobile .menu-mobile button {
          background-color: transparent;
          cursor: pointer;
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
          text-decoration: none;
          font: inherit;
        }

.header .main-navigation--mobile .menu-mobile a, .header .main-navigation--mobile .menu-mobile button {
          display: block;
          padding: 0px;
        }

.header .main-navigation--mobile .menu-mobile--primary ul {
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
          }

.header .main-navigation--mobile .menu-mobile--primary a, .header .main-navigation--mobile .menu-mobile--primary button {
            font-size: 1.5rem;
            line-height: 2rem;
            line-height: 2.5rem;
          }

.header .main-navigation--mobile .menu-mobile--primary .dropdown--nav a, .header .main-navigation--mobile .menu-mobile--primary .dropdown--nav button {
              font-size: 1.125rem;
              line-height: 1.75rem;
            }

.header .main-navigation--mobile .menu-mobile--secondary {
          padding-top: 2rem;
        }

.header .main-navigation--mobile .menu-mobile--secondary ul {
            display: flex;
            flex-direction: column;
          }

.header .main-navigation--mobile .menu-mobile--secondary a, .header .main-navigation--mobile .menu-mobile--secondary button {
            font-size: 1.25rem;
            line-height: 1.75rem;
            line-height: 1.5;
          }

.header .main-navigation--mobile .menu-mobile--featured {
          display: flex;
        }

a[href='#content'].skip-link {
  position: fixed;
  top: 0px;
  left: 50%;
  z-index: 90;
  display: block;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%;
  --tw-translate-y: -100%;
  background-color: var(--pageBG);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--pageText);
}

a[href='#content'].skip-link:focus {
    --tw-translate-y: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

.header-left {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 50;
  font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
}

.header-left.is-overlay {
    height: 0px;
  }

.header-left--outer {
    position: relative;
  }

.header-left--inner {
    position: relative;
    z-index: 20;
    background-color: var(--pageBGDark);
    padding: 1rem
  }

@media (min-width: 768px) {
    .header-left--inner {
      padding: 2rem;
    }
  }

.is-overlay .header-left--inner {
      background-color: transparent;
      transition-property: background-color, border-color, color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      transition-duration: 300ms;
    }

.has-bg .header-left--inner {
      background-color: var(--pageBGDark);
    }

.is-white .header-left--inner {
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      transition-delay: 75ms;
    }

.header-left--content {
    position: relative;
    z-index: 30;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@media (min-width: 768px) {
    .header-left--content {
      align-items: flex-start;
    }
  }

.header-left--border {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 20;
    border-top-width: 1px;
    transition: opacity 0s 0.6s linear;
  }

.is-white .header-left--border {
      --tw-border-opacity: 1;
      border-color: rgba(255, 255, 255, var(--tw-border-opacity));
      transition-duration: 0.2s;
      transition-delay: 0s;
      /* &:not(.is-hidden) {
        transition-delay: 0s;
      } */
    }

.header-left--border.is-hidden {
      opacity: 0;
      transition-delay: 0s;
    }

.header-left--observer {
    position: absolute;
    top: 2rem;
  }

.header-left--logo {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }

.header-left--logo-link {
    display: block;
    padding: 0.5rem;
  }

.header-left--logo-link svg {
      display: block;
      height: 0.75rem;
      width: auto;
      fill: currentColor;
    }

@media (min-width: 768px) {
      .header-left--logo-link svg {
        height: 1.25rem;
      }
    }

.header-left .main-navigation--desktop {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      display: none;
      align-items: center;
      justify-content: flex-end;
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity))
    }

@media (min-width: 768px) {
      .header-left .main-navigation--desktop {
        display: flex;
      }
    }

.header-left .main-navigation--desktop .menu-left, .header-left .main-navigation--desktop .menu-right {
        display: flex;
        align-items: center;
      }

.header-left .main-navigation--desktop ul {
        display: flex;
      }

.header-left .main-navigation--desktop ul li {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

.header-left .main-navigation--desktop ul button {
          background-color: transparent;
          cursor: pointer;
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
          text-decoration: none;
          font: inherit;
        }

.header-left .main-navigation--desktop ul a, .header-left .main-navigation--desktop ul button {
          display: block;
          padding: 0.5rem;
          font-size: 1rem;
          line-height: 1.5rem;
          line-height: 1.5;
        }

.header-left .main-navigation--mobile {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end
    }

@media (min-width: 768px) {
      .header-left .main-navigation--mobile {
        display: none;
      }
    }

.header-left .main-navigation--mobile .menu-mobile {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 50;
        display: flex;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        background-color: var(--pageBGDark);
        color: var(--pageText);
        height: calc(var(--vh, 1vh) * 100);
        max-width: 420px;
        will-change: transform;
      }

@media (min-width: 421px) {

.header-left .main-navigation--mobile .menu-mobile {
          border-right-width: 1px
      }
        }

.header-left .main-navigation--mobile .menu-mobile--backdrop {
          pointer-events: none;
          position: fixed;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          z-index: 40;
          --tw-bg-opacity: 1;
          background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
          --tw-bg-opacity: 0.4;
          opacity: 0;
          transition: opacity 0.15s linear;
        }

.header-left .main-navigation--mobile .menu-mobile--backdrop.is-active {
            pointer-events: auto;
            opacity: 1;
            -webkit-backdrop-filter: blur(6px);
                    backdrop-filter: blur(6px);
          }

.header-left .main-navigation--mobile .menu-mobile--inner {
          display: flex;
          flex: 1 1;
          flex-direction: column;
          overflow-y: scroll;
          background-color: var(--pageBG);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 1rem;
          padding-top: calc(var(--headerHeight, 10rem) + 1.5rem);
        }

.header-left .main-navigation--mobile .menu-mobile button {
          background-color: transparent;
          cursor: pointer;
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
          text-decoration: none;
          font: inherit;
        }

.header-left .main-navigation--mobile .menu-mobile a, .header-left .main-navigation--mobile .menu-mobile button {
          display: block;
          padding: 0px;
        }

.header-left .main-navigation--mobile .menu-mobile--primary ul {
            display: flex;
            flex-direction: column;
          }

.header-left .main-navigation--mobile .menu-mobile--primary a, .header-left .main-navigation--mobile .menu-mobile--primary button {
            font-size: 2.25rem;
            line-height: 2.5rem;
            line-height: 1.5;
          }

.header-left .main-navigation--mobile .menu-mobile--secondary {
          padding-top: 2rem;
        }

.header-left .main-navigation--mobile .menu-mobile--secondary ul {
            display: flex;
            flex-direction: column;
          }

.header-left .main-navigation--mobile .menu-mobile--secondary a, .header-left .main-navigation--mobile .menu-mobile--secondary button {
            font-size: 1.25rem;
            line-height: 1.75rem;
            line-height: 1.5;
          }

.header-left .main-navigation--mobile .menu-mobile--featured {
          display: flex;
        }

.header-left .menu-toggle {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 60;
    background-color: transparent
  }

@media (min-width: 768px) {
    .header-left .menu-toggle {
      display: none;
    }
  }

.header-left .menu-toggle {
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
  }

.is-overlay:not(.has-bg) .header-left .menu-toggle {
      transition-property: background-color, border-color, color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      transition-duration: 300ms;
    }

.header-left .menu-toggle.is-open {
      color: var(--pageText);
    }

.header-left .hamburger {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 1.75rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.header-left .hamburger::before, .header-left .hamburger::after {
      position: relative;
      display: block;
      height: 0px;
      width: 100%;
      border-top-width: 1px;
      --tw-border-opacity: 1;
      border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    }

.header-left .hamburger::before,
    .header-left .hamburger::after {
      content: '';
      margin: 6px 0;
      transition: opacity 0s 0.2s linear, transform 0.2s 0.2s ease-out;
    }

.is-open .header-left .hamburger::before, .is-open .header-left .hamburger::after {
        opacity: 0;
        transition: opacity 0s 0.2s linear, transform 0.2s ease-out;
      }

.is-open .header-left .hamburger::before {
        transform: translateY(5px);
      }

.is-open .header-left .hamburger::after {
        transform: translateY(-5px);
      }

.header-left .hamburger--icon {
      position: relative;
      display: block;
      height: 0px;
      width: 100%;
      border-top-width: 1px;
      --tw-border-opacity: 1;
      border-color: rgba(255, 255, 255, var(--tw-border-opacity));
      transition: border 0s 0.2s linear;
    }

.is-open .header-left .hamburger--icon {
        border-color: transparent;
      }

.header-left .hamburger--icon::before, .header-left .hamburger--icon::after {
        position: absolute;
        display: block;
        height: 0px;
        width: 100%;
        border-top-width: 1px;
        opacity: 0;
      }

.header-left .hamburger--icon::before,
      .header-left .hamburger--icon::after {
        content: '';
        top: calc(50% - 0.5px);
        transition: opacity 0s 0.2s, transform 0.2s ease-out;
      }

.is-open .header-left .hamburger--icon::before, .is-open .header-left .hamburger--icon::after {
          opacity: 1;
          transition: transform 0.2s 0.2s ease-out;
        }

.is-open .header-left .hamburger--icon::before {
          transform: rotate(45deg);
        }

.is-open .header-left .hamburger--icon::after {
          transform: rotate(-45deg);
        }

.header-left .cart-toggle {
    position: relative;
    margin-right: -0.5rem;
    display: flex;
    align-items: center;
    background-color: transparent
  }

@media (min-width: 768px) {
    .header-left .cart-toggle {
      padding: 0.5rem;
    }
  }

.header-left .cart-toggle {
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
    font: inherit;
  }

.header-left .cart-toggle--count {
      margin-left: 0.5rem;
      height: 1.5rem;
      height: 1.75rem;
      width: 1.5rem;
      width: 1.75rem;
      border-radius: 9999px;
      background-color: var(--pageText);
      font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
      font-size: 0.875rem;
      line-height: 1.25rem;
      line-height: 2;
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      color: var(--pageBGDark);
      opacity: 0.3;
    }

.header-left .cart-toggle--count.is-active {
        opacity: 1;
      }

.mega-nav {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 100%;
  z-index: 30;
  display: none
}

@media (min-width: 768px) {
  .mega-nav {
    display: block;
  }
}

/* Background of opened mega nav */

.mega-nav--bg {
    display: none
  }

@media (min-width: 768px) {
    .mega-nav--bg {
      display: block;
    }
  }

.mega-nav--bg::before {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 100%;
      z-index: 10;
      height: 1px;
      background-color: var(--pageBG);
      will-change: transform;
      transform-origin: 0 0;
      transform: scaleY(var(--h));
      transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      content: '';
    }

/* account for the header when styled as an overlay that hasn't scrolled */

.is-overlay:not(.has-bg) .mega-nav--bg::before {
        top: 0px;
      }

.mega-nav--bg::after {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 100%;
      z-index: 20;
      margin-top: -1px;
      transform: translate3d(0, var(--hpx), 0);
      transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      content: '';
    }

/* account for the header when styled as an overlay that hasn't scrolled */

.is-overlay:not(.has-bg) .mega-nav--bg::after {
        top: 0px;
      }

.mega-nav--border {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 100%;
    z-index: 10;
    height: 1px;
  }

.mega-nav--backdrop {
    pointer-events: none;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 0;
    --tw-bg-opacity: 0;
    opacity: 0;
    transition: opacity 0.1s linear;
  }

.mega-nav--backdrop.is-active {
      pointer-events: auto;
      opacity: 1;
    }

/* Customize megadropdown navigation buttons: */

/* (you should mostly offload these to the general header links/button styles) */

.mega-toggle--icon {
    margin-right: 0.5rem;
    display: inline-block;
    border-radius: 9999px;
    width: 4px;
    height: 4px;
  }

.is-open .mega-toggle--icon {
      background-color: var(--pageText);
      -webkit-animation: pulse 2s infinite;
              animation: pulse 2s infinite;
    }

.mega-item {
  pointer-events: none;
  visibility: hidden;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 10;
  margin-top: -1px;
  overflow: hidden;
  transition: visibility 0.1s;
}

.mega-item.is-active {
    pointer-events: auto;
    visibility: visible;
    transition-delay: 0s;
  }

.mega-item--content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    border-bottom-width: 1px;
    border-color: var(--pagePrimary);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    will-change: transform;
  }

.mega-item ul {
    list-style-type: none;
  }

.mega-item ul a, .mega-item ul button {
      display: inline-block;
      line-height: 1.5;
    }

.mega-item ul a:hover, .mega-item ul button:hover {
      opacity: 0.4;
    }

.mega-item ul a, .mega-item ul button {
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      transition-duration: 200ms;
    }

.mega-item--featured {
    position: relative;
    display: grid;
    width: 100%;
    max-width: 42rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    border-left-width: 1px;
    border-color: var(--pageBGDark);
    padding-left: 2rem;
  }

.mega-item--featured-title {
      position: absolute;
      top: 0px;
      right: 100%;
      display: block;
      width: 0.5rem;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

.mega-item--featured-title span {
        position: absolute;
        top: 50%;
        left: 0px;
        display: block;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        --tw-rotate: 90deg;
        overflow: hidden;
        white-space: nowrap;
        transform-origin: 0 0;
      }

.footer {
  position: relative;
  overflow: hidden;
  border-top-width: 1px;
  border-color: var(--pagePrimary);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.footer--grid {
    display: grid;
    grid-gap: 1px;
    gap: 1px;
    background-color: var(--pagePrimary);
  }

@media (min-width: 768px) {
    .footer--grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

@media (min-width: 1200px) {
    .footer--grid {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

.footer--block {
    display: flex;
    flex-direction: column;
    background-color: var(--pageBG);
    padding: 2rem;
    text-align: center;
  }

@media (min-width: 940px) {
    .footer--block {
      padding: 2rem;
    }
  }

@media (min-width: 1200px) {
    .footer--block {
      text-align: left;
    }
  }

.footer .form {
    margin-top: auto;
  }

.footer .menu-footer {
    margin-top: 0.25rem;
    display: flex;
    list-style-type: none;
    flex-direction: column
  }

@media (min-width: 1200px) {
    .footer .menu-footer {
      margin-bottom: 2rem;
    }
  }

.footer .menu-footer a {
      display: inline-block;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

.footer .menu-footer a:hover {
      opacity: 0.4;
    }

.footer .menu-social {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: inline-flex;
    flex-wrap: wrap
  }

@media (min-width: 1200px) {
    .footer .menu-social {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
    }
  }

.footer .menu-social a {
      display: block;
      height: 2rem;
      width: 2rem
    }

.footer .menu-social a:hover {
      opacity: 0.4;
    }

.footer .menu-social a svg {
        display: block;
        height: 100%;
        width: 100%;
      }

.footer--extras {
    margin-top: auto;
  }

.footer--locale {
    padding-bottom: 0.5rem;
  }

.footer--disclaimer {
    margin-top: 0.75rem;
  }

.footer--disclaimer a {
      font-size: 0.75rem;
      line-height: 1rem;
    }

.footer--disclaimer a:not(:first-child) {
        margin-left: 0.75rem;
      }

.footer--logo {
    width: 1.25rem;
  }

.cart {
  pointer-events: none;
  visibility: hidden;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 90;
  height: 100vh;
  width: 100%;
  max-width: 48rem;
  background-color: var(--pageBG);
  color: var(--pageText);
  height: calc(var(--vh, 1vh) * 100);
  will-change: transform;
  transition: visibility 0.2s 0.8s;
}

@media (min-width: 769px) {

.cart {
    border-left-width: 1px
}
  }

.cart.is-active {
    pointer-events: auto;
    visibility: visible;
    transition-delay: 0s;
  }

.cart.is-updating {
    cursor: wait !important;
  }

.cart--backdrop {
    pointer-events: none;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 80;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.4;
    opacity: 0;
    transition: opacity 0.15s linear;
  }

.cart--backdrop.is-active {
      pointer-events: auto;
      opacity: 1;
      -webkit-backdrop-filter: blur(6px);
              backdrop-filter: blur(6px);
    }

.cart--inner {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }

.cart--header {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5
  }

@media (min-width: 768px) {
    .cart--header {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

.cart--header::after {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-top-width: 1px;
      content: '';
    }

.cart--title {
    margin-right: auto;
    display: flex;
    height: 3rem;
    align-items: center;
  }

.cart--count {
    margin-left: 0.5rem;
    opacity: 0.3;
  }

.cart--content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow-y: scroll;
    padding: 1rem;
  }

@media (min-width: 768px) {
    .cart--content {
      padding: 2rem;
    }
  }

.cart--footer {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

@media (min-width: 768px) {
    .cart--footer {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
    }
  }

.cart--subtotal {
    display: flex;
    justify-content: space-between;
    border-top-width: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

@media (min-width: 768px) {
    .cart--subtotal {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

.cart--message {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
  }

@media (min-width: 768px) {
    .cart--message {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

.cart--empty {
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 7rem;
    text-align: center;
  }

.cart-item {
  position: relative;
  display: flex;
}

.cart-item:not(:last-child) {
    margin-bottom: 2rem;
    border-bottom-width: 1px;
    padding-bottom: 2rem;
  }

.is-updating .cart-item {
    pointer-events: none;
  }

.cart-item--photo {
    position: relative;
    margin: 0px;
    width: 25%;
    flex-shrink: 0;
  }

@media (min-width: 768px) {
    .cart-item--photo {
      width: 33.333333%;
    }
  }

.cart-item--photo {
    max-width: 10rem;
  }

.cart-item--details {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-left: 1rem;
  }

@media (min-width: 768px) {
    .cart-item--header {
      display: flex;
    }
  }

.cart-item--header .price {
      margin-left: auto;
    }

@media (min-width: 768px) {
        .cart-item--header .price--current {
          font-size: 1.125rem;
          line-height: 1.75rem;
          line-height: 1.5;
        }
      }

@media (min-width: 768px) {
    .cart-item--title {
      padding-right: 3rem;
    }
  }

.cart-item--variant {
    margin-bottom: 0.25rem;
    line-height: 1.625;
    letter-spacing: 0.05em;
  }

.cart-item--name {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

@media (min-width: 768px) {
    .cart-item--name {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

.cart-item--link {
    display: block;
    color: currentColor;
  }

.cart-item--link::after {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 10;
      display: block;
      content: '';
    }

.cart-item--title {
    margin: 0px;
    flex: 1 1;
  }

.cart-item--price {
    margin-left: 1rem;
  }

.cart-item--variant {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

.cart-item--tools {
    position: relative;
    z-index: 10;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
  }

.blog--date {
    font-size: 0.75rem;
    line-height: 1rem;
  }

.blog--gallery {
    margin-top: 2rem;
    max-width: 80rem;
  }

.blog--content {
    margin-top: 1.5rem;
  }

.blog--content p {
      max-width: 65ch;
    }

.blog--content figure {
      max-width: 56rem;
    }

.blog-preview__post {
    position: relative;
    display: grid;
    grid-auto-flow: row dense;
  }

@media (min-width: 1200px) {
    .blog-preview__post {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }

.blog-preview__date {
    font-size: 0.75rem;
    line-height: 1rem;
  }

.blog-preview__content {
    padding: 2rem;
  }

@media (min-width: 1200px) {
    .blog-preview__content {
      grid-column: span 6 / span 6;
      display: flex;
      align-items: flex-end;
    }
  }

.blog-preview__content-wrapper {
    width: 66.666667%;
  }

.blog-preview__page-title {
    padding: 2rem;
  }

.blog-preview__description {
    margin-top: 1.25rem;
  }

.blog-preview__description p {
      max-width: 65ch;
    }

.blog-preview__description figure {
      max-width: 56rem;
    }

.hero {
  position: relative;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  max-height: calc(var(--vh, 1vh) * 100);
}

@media (max-height: 500px) {

.hero {
    min-height: 500px
}
  }

.hero--bg {
    position: relative;
    z-index: 0;
    height: 100%;
    max-height: 100vh;
    width: 100%;
  }

.hero--bg.is-desktop {
      display: none;
    }

@media (min-width: 768px) {
      .hero--bg.is-desktop {
        display: block;
      }
    }

.hero--bg.is-desktop {
      padding-top: calc((9 / 16) * 100%);
    }

@media (min-width: 768px) {
      .hero--bg.is-mobile {
        display: none;
      }
    }

.hero--bg.is-mobile {
      padding-top: calc((4 / 3) * 100%);
    }

.hero--overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(0 0 0 / 12%);
  }

.hero--content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 6rem;
    padding-bottom: 3rem;
    text-align: center;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

.divider {
  position: relative;
  height: 100vh;
}

.divider.full-size {
    height: auto;
  }

.retailers-list {
  position: relative;
  padding: 1rem
}

@media (min-width: 768px) {
  .retailers-list {
    padding: 2rem;
  }
}

.retailers-list--list {
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

.retailers-list--retailer {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 100%;
    overflow: hidden;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

@media (min-width: 768px) {
    .retailers-list--retailer {
      width: 50%;
    }
  }

@media (min-width: 940px) {
    .retailers-list--retailer {
      width: 33.333333%;
    }
  }

@media (min-width: 1200px) {
    .retailers-list--retailer {
      width: 25%;
    }
  }

.retailers-list--name {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

.retailers-list--address {
    margin-top: 0.5rem;
  }

.retailers-list--website {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

.featured-retailers {
  position: relative;
  padding: 1rem
}

@media (min-width: 768px) {
  .featured-retailers {
    padding: 2rem;
  }
}

.featured-retailers--list {
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

.featured-retailers--retailer {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 50%;
    width: 100%;
    overflow: hidden;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    text-align: center;
  }

@media (min-width: 768px) {
    .featured-retailers--retailer {
      width: 33.333333%;
    }
  }

@media (min-width: 940px) {
    .featured-retailers--retailer {
      width: 25%;
    }
  }

@media (min-width: 1200px) {
    .featured-retailers--retailer {
      width: 20%;
    }
  }

.featured-retailers--retailer {
    max-width: 300px;
  }

.featured-retailers--name {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

.featured-retailers--address {
    margin-top: 0.5rem;
  }

.featured-retailers--website {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

.press-list {
  position: relative;
  margin-top: 5rem;
  padding: 1rem
}

@media (min-width: 768px) {
  .press-list {
    padding: 2rem;
  }
}

.press-list--featured {
    background-color: var(--pageBGDark);
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

.press-text-list {
  position: relative;
  padding: 1rem
}

@media (min-width: 768px) {
  .press-text-list {
    padding: 2rem;
  }
}

.press-text-list--outlets {
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

.press-text-list--press {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
  }

@media (min-width: 768px) {
    .press-text-list--press {
      width: 50%;
    }
  }

@media (min-width: 940px) {
    .press-text-list--press {
      width: 33.333333%;
    }
  }

@media (min-width: 1200px) {
    .press-text-list--press {
      width: 25%;
    }
  }

.press-text-list--name {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

.press-text-list--address {
    margin-top: 0.5rem;
  }

.press-text-list--website {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

.press-text-list--excerpt:not(:first-child) {
    margin-top: 2rem;
  }

.press-text-list--read-more {
    font-size: 0.75rem;
    line-height: 1rem;
  }

.press-text-list--read-more svg {
      height: 1rem;
    }

.press-text-list--date, .press-text-list--description {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

.press-list-featured--heading {
    text-align: center;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

.press-list-featured--logos {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

.press-list-featured--press {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 50%;
    width: 100%;
    overflow: hidden;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    text-align: center;
  }

@media (min-width: 768px) {
    .press-list-featured--press {
      width: 33.333333%;
    }
  }

@media (min-width: 940px) {
    .press-list-featured--press {
      width: 25%;
    }
  }

@media (min-width: 1200px) {
    .press-list-featured--press {
      width: 20%;
    }
  }

.press-list-featured--press {
    max-width: 300px;
  }

.press-list-featured--logo figure {
    margin-left: auto;
    margin-right: auto;
  }

.press-list-featured--logo {
    margin: 0.25rem;
    display: block;
  }

.timeline-section {
  /* @apply container px-5 py-24 mx-auto relative; */
  width: 100%
}

@media (min-width: 480px) {
  .timeline-section {
    max-width: 480px;
  }
}

@media (min-width: 768px) {
  .timeline-section {
    max-width: 768px;
  }
}

@media (min-width: 940px) {
  .timeline-section {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .timeline-section {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .timeline-section {
    max-width: 1600px;
  }
}

.timeline-section {
  margin-left: auto;
  margin-right: auto;
  border-top-width: 1px;
  border-color: var(--pageBGDark);
  padding: 1rem;
}

@media (min-width: 768px) {
  .timeline-section {
    padding: 2rem;
  }
}

.timeline-section--wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

@media (min-width: 940px) {
    .timeline-section--timeline {
      width: 50%;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 2.5rem;
    }
  }

@media (min-width: 1200px) {
    .timeline-section--timeline {
      width: 40%;
    }
  }

.timeline-section--event {
    position: relative;
    display: flex;
    padding-bottom: 3rem;
  }

.timeline-section--line {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    height: 100%;
    width: 2.5rem;
    align-items: center;
    justify-content: center;
  }

.timeline-section--line-fill {
    pointer-events: none;
    height: 100%;
    width: 0.25rem;
    background-color: var(--pageTheme);
  }

.timeline-section--icon {
    position: relative;
    z-index: 10;
    display: inline-flex;
    height: 2.5rem;
    width: 2.5rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: var(--pageTheme);
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

.timeline-section--last-event {
    position: relative;
    display: flex;
  }

.timeline-section--content {
    flex-grow: 1;
    padding-left: 1rem;
  }

.timeline-section--title {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
  }

.timeline-section--visual {
    margin-top: 3rem;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
  }

@media (min-width: 940px) {
    .timeline-section--visual {
      margin-top: 0px;
      width: 50%;
    }
  }

@media (min-width: 1200px) {
    .timeline-section--visual {
      width: 60%;
    }
  }

.image-grid {
  padding: 1rem
}

@media (min-width: 768px) {
  .image-grid {
    padding: 2rem;
  }
}

.image-grid--wrapper {
    width: 100%;
  }

@media (min-width: 480px) {
    .image-grid--wrapper {
      max-width: 480px;
    }
  }

@media (min-width: 768px) {
    .image-grid--wrapper {
      max-width: 768px;
    }
  }

@media (min-width: 940px) {
    .image-grid--wrapper {
      max-width: 940px;
    }
  }

@media (min-width: 1200px) {
    .image-grid--wrapper {
      max-width: 1200px;
    }
  }

@media (min-width: 1600px) {
    .image-grid--wrapper {
      max-width: 1600px;
    }
  }

.image-grid--wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }

.image-grid--content {
    margin-bottom: 2.5rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

.image-grid--title {
    margin-bottom: 0px;
    max-width: 65ch;
    padding-bottom: 0px;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 500;
  }

@media (min-width: 940px) {
    .image-grid--title {
      width: 33.333333%;
    }
  }

.image-grid--caption {
    margin-top: 1rem;
    max-width: 65ch;
  }

@media (min-width: 768px) {
    .image-grid--caption {
      margin-top: 0px;
      padding-left: 1.25rem;
    }
  }

@media (min-width: 940px) {
    .image-grid--caption {
      width: 66.666667%;
    }
  }

.image-grid--images {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

.image-grid--image-block {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

@media (min-width: 768px) {
    .image-grid--image-block {
      width: 50%;
    }
  }

.image-grid--image-small-wrapper {
    width: 50%;
    padding: 0.25rem;
  }

@media (min-width: 940px) {
    .image-grid--image-small-wrapper {
      padding: 0.5rem;
    }
  }

.image-grid--image-small {
    display: block;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
  }

.image-grid--image-large-wrapper {
    width: 100%;
    padding: 0.25rem;
  }

@media (min-width: 940px) {
    .image-grid--image-large-wrapper {
      padding: 0.5rem;
    }
  }

.image-grid--image-large {
    display: block;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
  }

.image-grid--image {
    position: relative;
    height: 100%;
    min-height: 200px;
    padding: 0.25rem;
  }

@media (min-width: 768px) {
    .image-grid--image {
      min-height: 400px;
    }
  }

@media (min-width: 1200px) {
    .image-grid--image {
      min-height: 600px;
    }
  }

.image-grid--button {
    margin-top: 1rem;
  }

.image-grid--details {
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.1;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

@media (min-width: 940px) {
    .image-grid--details {
      --tw-bg-opacity: 0.25;
      opacity: 0;
    }

    .image-grid--details:hover {
      opacity: 1;
    }
  }

.image-grid--details {
    transition: opacity 0.45s linear;
  }

.auto-columns {
  position: relative;
  padding: 1rem;
}

@media (min-width: 768px) {
  .auto-columns {
    padding: 2rem;
  }
}

.auto-columns--column {
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.collection {
  overflow: hidden;
}

.collection--grid {
    margin: -1px;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

@media (min-width: 940px) {
    .collection--grid {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

.collection--pagination {
    position: relative;
    border-top-width: 1px;
    background-color: var(--pageBG);
    padding: 1rem;
    text-align: center;
  }

@media (min-width: 768px) {
    .collection--pagination {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

.collection-count {
  position: relative;
  margin-left: 0.5rem;
  display: inline-block;
  line-height: 1;
  vertical-align: super;
  font-size: 0.5em;
}

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
}

.collection .product-card, .marquee--product .product-card {
    padding-bottom: 2rem;
  }

.collection .product-card,
  .marquee--product .product-card {
    margin: -0.5px;
  }

.product-card.is-inline {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

.product-card--out-of-stock-label {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    z-index: 20;
  }

.product-card.out-of-stock .product-card--thumb {
      opacity: 0.5;
    }

.product-card--thumb {
    z-index: 0;
  }

.product-card--photo {
    position: relative;
    height: 24rem;
    overflow: hidden
  }

@media (min-width: 768px) {
    .product-card--photo {
      height: 100vh;
    }
  }

@media (min-width: 940px) {

.product-card--photo {
      height: 27vw;
  }
    }

.product-card--photo .is-hover {
      visibility: hidden;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 10;
      opacity: 0;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

.product-card:hover .product-card--photo .is-hover {
        visibility: visible;
        opacity: 1;
      }

.product-card--gallery {
    position: relative;
  }

.product-card--gallery .carousel--hud {
        z-index: 30;
      }

.product-card--details {
    text-align: center;
  }

.product-card--visuals + .product-card--details {
      margin-top: 1.5rem;
    }

.is-inline .product-card--visuals + .product-card--details {
        margin-top: 1rem;
      }

.product-card--title {
    margin: 0px !important;
  }

.is-featured .product-card--title {
      font-size: 1rem;
      line-height: 1.5rem;
    }

.is-inline .product-card--title {
      font-size: 1.5rem;
      line-height: 2rem;
    }

.product-card--title + .price {
      margin-top: 0.5rem;
    }

.product-card--link {
    display: block;
    color: currentColor;
    text-decoration: none;
  }

.product-card--link::after {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 20;
      display: block;
      content: '';
    }

.product-card--option {
    position: relative;
    z-index: 30;
    margin-top: 1rem;
  }

.product-card--option .option {
      margin: 0px;
      border-width: 0px;
      padding: 0px;
    }

.product-card--option .option--values {
        margin-left: auto;
        margin-right: auto;
      }

.price--previous {
    padding-right: 0.75rem;
    text-decoration: line-through;
  }

@media (min-width: 768px) {
    .price--current {
      font-size: 1rem;
      line-height: 1.5rem;
      line-height: 1.5;
    }
  }

.price--discount {
    margin-left: 0.5rem;
    border-radius: 0.125rem;
    background-color: var(--pageText);
    padding: 0.25rem;
    font-size: .625rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.1em;
    color: var(--pageBG);
  }

.product-featured {
  position: relative;
  display: flex;
  flex-direction: column;
}

.product-featured h2 {
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 200ms;
  }

.product-featured:hover .product-featured h2 {
      visibility: visible;
      opacity: 1;
    }

.product-featured:hover h2 {
      opacity: 0.4;
    }

.collection-tiled-images {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.collection-tiled-images--products {
    margin: -1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }

.collection-tiled-images--product {
    width: 100%;
    padding: 0.75rem;
  }

@media (min-width: 768px) {
    .collection-tiled-images--product {
      width: 33.333333%;
    }
  }

.collection-tiled-images--content {
    position: relative;
    display: flex;
    cursor: pointer;
    text-align: center;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

.collection-tiled-images--details {
    position: relative;
    z-index: 10;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

@media (min-width: 940px) {
    .collection-tiled-images--details {
      opacity: 0;
    }
  }

.collection-tiled-images--details {
    aspect-ratio: 2/3;
    transition: opacity 0.45s linear;
  }

.collection-tiled-images .is-hover {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.45s linear;
  }

.collection-tiled-images--product:hover .collection-tiled-images--details {
    opacity: 1;
  }

.collection-tiled-images--product:hover .is-hover {
    opacity: 1;
  }

/* @apply border-b; */

.product--content {
    position: relative;
    display: grid;
  }

@media (min-width: 768px) {
    .product--content {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }

.product--gallery {
    position: relative;
    background-color: var(--pagePrimary)
  }

@media (min-width: 768px) {
    .product--gallery {
      grid-column: span 7 / span 7;
    }
  }

.product--gallery .carousel {
      margin-left: auto;
      margin-right: auto;
      max-width: 64rem;
    }

.product--details {
    display: flex;
    flex-direction: column;
    background-color: var(--pageBG);
    padding: 1.25rem;
  }

@media (min-width: 768px) {
    .product--details {
      grid-column: span 5 / span 5;
    }
  }

@media (min-width: 940px) {
    .product--details {
      padding: 6rem;
    }
  }

.product--specs-heading {
    margin-top: 2rem;
    border-bottom-width: 1px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

.product--specs-description {
    margin-top: 0.5rem;
  }

.product--info {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    width: 100%;
    max-width: 36rem;
  }

.product--desc {
    margin-top: 0.75rem;
  }

.product--header {
    display: flex;
    padding-bottom: 0.5rem;
  }

.product .price {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

.product .price--discount {
      margin-bottom: 0.5rem;
      border-radius: 0.125rem;
      background-color: var(--pageText);
      padding: 0.25rem;
      font-size: .625rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.25;
      letter-spacing: 0.1em;
      color: var(--pageBG);
    }

.product--title {
    padding-right: 2rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

@media (min-width: 768px) {
    .product--title {
      padding-right: 3rem;
    }
  }

.product--name {
    margin: 0px;
    font-size: 1.5rem;
    line-height: 2rem;
    line-height: 1.375;
  }

.product--variant {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.625;
    letter-spacing: 0.05em;
  }

.product--variant .label {
      margin-top: -0.25rem;
      margin-bottom: -0.25rem;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      --tw-scale-x: .9;
      --tw-scale-y: .9;
    }

.product--options {
    margin-top: 1.25rem;
  }

.product--actions {
    margin-top: 1.25rem;
    background-color: var(--pageBG);
    padding-top: 1rem;
    padding-bottom: 1rem
  }

@media (min-width: 768px) {
    .product--actions {
      display: flex;
    }
  }

/* @apply sm:flex sticky bottom-0 mt-4 p-4 md:p-8 border-t bg-pageBG is-textured text-center; */

.product--actions .counter {
      display: none;
    }

@media (min-width: 768px) {
      .product--actions .counter {
        display: inline-flex;
      }
    }

.product--actions-learn-more {
    margin-left: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

.product--waitlist {
    position: relative;
    margin-top: 2rem;
    width: 100%;
  }

.product--waitlist-description {
    text-align: left;
    font-size: 0.75rem;
    line-height: 1rem;
  }

.product--learn-more {
    position: relative;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.product--learn-more svg {
      height: 1rem;
    }

.product--shared-info {
    max-width: 1600px;
    padding: 1rem;
  }

@media (min-width: 768px) {
    .product--shared-info {
      padding: 2rem;
    }
  }

.option {
  margin-top: 1rem;
  display: flex;
  border-top-width: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.option--title {
    margin-right: 3rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

.option--values {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    grid-gap: 5px;
    gap: 5px;
    grid-column-gap: 5px;
    -moz-column-gap: 5px;
         column-gap: 5px;
    grid-row-gap: 5px;
    row-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }

.option--swatch {
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
  }

.product-card-minimal {
  position: relative;
  display: flex;
  flex-direction: column;
}

.product-card-minimal .price {
    margin-top: 0.5rem;
    display: inline;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

.product-card-minimal--thumb {
    z-index: 0;
  }

.product-card-minimal--photo {
    position: relative;
    height: 24rem;
    overflow: hidden;
  }

.product-card-minimal--title {
    margin: 0px !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

.product-card-minimal--link {
    display: block;
    color: currentColor;
    text-decoration: none;
  }

.product-card-minimal--link::after {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 20;
      display: block;
      content: '';
    }

.product-card-minimal--option {
    position: relative;
    z-index: 30;
    margin-top: 1rem;
  }

.product-card-minimal--option .option {
      margin: 0px;
      border-width: 0px;
      padding: 0px;
    }

.product-card-minimal--option .option--values {
        margin-left: auto;
        margin-right: auto;
      }

.theme-switch {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.theme-switch--toggle {
    display: flex;
    align-items: center;
    border-radius: 9999px;
    border-width: 1px;
    background-color: transparent;
    padding: 0.5rem;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-family: Zen Maru Gothic, 'Century Gothic', arial, sans-serif;
    text-decoration: none;
  }

.theme-switch--label {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }

.theme-switch .swatch::before {
      opacity: 1;
    }

.swatch {
  position: relative;
  display: block;
  border-radius: 9999px;
  padding: 0px;
  width: 20px;
  height: 20px;
  background-color: var(--swatchColor);
}

/* ring */

.swatch::before {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 9999px;
    opacity: 0.1;
    border: 1px solid var(--swatchBorder);
    content: "";
  }

/* active */

.swatch::after {
    position: absolute;
    border-radius: 9999px;
    border-width: 1px;
    border-color: transparent;
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 200ms;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    content: "";
  }

.is-active .swatch::after {
      border-color: var(--pageText);
      opacity: 1;
    }

li:not(.is-soldout):not(.is-active) button:hover .swatch::after {
      border-color: var(--pageText);
      opacity: 0.5;
    }

.is-soldout .swatch::after {
      top: -0.25rem;
      right: -0.25rem;
      bottom: -0.25rem;
      left: -0.25rem;
      border-color: transparent;
      opacity: 1;
      background: linear-gradient(45deg,transparent calc(50% - 1px),var(--pageText),transparent calc(50% + 1px)), linear-gradient(-45deg,transparent calc(50% - 1px),var(--pageText),transparent calc(50% + 1px));
    }

.squares-items {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0px;
  gap: 0px;
}

.squares-items.square-items--narrow {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-right-width: 1px;
    border-left-width: 1px;
  }

@media (min-width: 1200px) {
    .squares-items.square-items--narrow {
      width: 66.666667%;
    }
  }

.squares-items + .squares-items {
  border-bottom-width: 1px;
}

.square-item {
  grid-column: span 2 / span 2;
  display: flex;
  background-color: var(--pageBG)
}

@media (min-width: 768px) {
  .square-item {
    min-height: 100vh;
  }
}

@media (min-width: 940px) {
  .square-item {
    grid-column: span 1 / span 1;
  }
}

.square-item.square-item--secondary {
    background-color: var(--pageSecondary);
    color: var(--pageText);
  }

.square-item.square-item--highlight {
    background-color: var(--pageHighlight);
    color: var(--pageText);
  }

.square-item.square-item--dark {
    background-color: var(--pageDark);
  }

.square-item.square-item--primary {
    background-color: var(--pagePrimary);
    color: var(--pageBG);
  }

.square-item--wrapper {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1 1;
  flex-direction: column
}

@media (min-width: 768px) {
  .square-item--wrapper {
    padding: 2.5rem;
  }
}

.square-items--narrow .square-item--wrapper {
    padding: 1.25rem;
  }

.square-item--details {
  margin-top: 0.75rem;
  max-width: 65ch;
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.square-item--primary .square-item--details, .square-item--dark .square-item--details {
    color: var(--pageBG);
  }

.square-item--details .rc p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

.square-item--content {
  position: relative;
  max-width: 42rem;
  padding: 1rem
}

@media (min-width: 768px) {
  .square-item--content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.square-items--narrow .square-item--content {
    padding: 0px;
  }

@media (min-width: 768px) {
    .square-items--narrow .square-item--content {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

.square-item--photo {
  position: relative;
  min-height: 500px;
}

@media (min-width: 768px) {
  .square-item--photo {
    min-height: 800px;
  }
}

.square-item--photo-wrap {
  position: relative;
}

.square-item--product-wrap {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 66.666667%;
  max-width: 36rem;
}

.square-item--caption {
  position: absolute;
  top: 3rem;
  left: auto;
  display: block;
  width: 20rem;
}

.square-item--caption span {
    position: absolute;
    left: 0px;
    display: block;
    transform-origin: top left;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-rotate: 90deg;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    mix-blend-mode: difference
  }

@media (min-width: 940px) {
    .square-item--caption span {
      left: 1.25rem;
    }
  }

@media (min-width: 1200px) {
    .square-item--caption span {
      left: 1.5rem;
    }
  }

.square-item--primary .square-item--caption span {
      color: var(--pageText);
      mix-blend-mode: normal;
    }

.square-item--link {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
  text-align: center;
  font-size: 3rem;
  line-height: 1;
  opacity: 0;
}

.square-item--link:hover {
  opacity: 1;
}

.square-item--link {
  padding-top: 50%;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  transition: opacity 0.2s linear;
}

@media (min-width: 768px) {
    .square-item--emphasise .square-item--wrapper {
      padding: 0px;
    }
  }

.square-item--emphasise .square-item--photo-wrap {
    height: 100%;
  }

.square-item--emphasise .square-item--photo {
    height: 100%;
    padding-top: 0px;
  }

.square-item--emphasise .square-item--details {
    position: absolute;
    right: 0px;
    bottom: 0.75rem;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.8;
    padding: 0.75rem;
    /* @apply absolute bottom-3 right-3 mix-blend-difference text-white; */
  }

.square-item:hover .square-item--emphasise .square-item--details {
      opacity: 0;
    }

.square-item:hover .square-item--emphasise .square-item--details:hover {
      opacity: 1;
    }

/* .squares:hover .card--dot {
  transform: scale(1);
}
.card--dot {
  position: relative;
  height: 0;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(240, 81, 81, var(--tw-bg-opacity));
  background: linear-gradient(0, blue, blue 50%, white 50%);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  padding-top: 100%;
  transform: scale(0.1);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.squares:hover .card--dot span {
  opacity: 1;
}
.card--dot span {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 600;
  opacity: 0;
  transform: translateY(-50%);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
} */

.side-by-side {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-gap: 0px;
  gap: 0px;
}

.has-block-angles .side-by-side__image {
      box-shadow: 0px 0px 3px 1px #0000004d;
    }

.side-by-side__content {
    background-color: var(--pageBG);
  }

.has-block-angles .side-by-side__content {
      background-color: transparent;
    }

.side-by-side.has-block-angles {
    background-color: transparent;
  }

.block-angle-decorator {
  position: relative;
  z-index: 1;
}

.block-angle-decorator::before,
  .block-angle-decorator::after {
    background: inherit;
    content: '';
    display: block;
    left: 0px;
    position: absolute;
    right: 0px;
    z-index: -1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

.brand-profile {
  width: 100%
}

@media (min-width: 480px) {
  .brand-profile {
    max-width: 480px;
  }
}

@media (min-width: 768px) {
  .brand-profile {
    max-width: 768px;
  }
}

@media (min-width: 940px) {
  .brand-profile {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .brand-profile {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .brand-profile {
    max-width: 1600px;
  }
}

.brand-profile {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.brand-profile .rc {
    margin-top: 2rem;
  }

.brand-profile .rc p {
      font-size: 1.5rem;
      line-height: 2rem;
      line-height: 1.5;
    }

@media (min-width: 768px) {
      .brand-profile .rc p {
        font-size: 1.875rem;
        line-height: 2.25rem;
        line-height: 1.5;
      }
    }

.brand-profile__bg-image {
    z-index: -1;
  }

.brand-profile__container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
  }

@media (min-width: 768px) {
    .brand-profile__container {
      flex-direction: row;
      padding: 2rem;
    }
  }

@media (min-width: 1200px) {
    .brand-profile__container {
      width: 83.333333%;
    }
  }

.brand-profile__container {
    background: rgba(255, 255, 255, 0.5);
  }

.brand-profile__designers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

@media (min-width: 768px) {
    .brand-profile__designers {
      width: 33.333333%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-right: 2rem;
    }
  }

.brand-profile__designer {
    margin-top: 1.5rem;
    width: 50%;
  }

@media (min-width: 768px) {
    .brand-profile__designer {
      width: 100%;
    }
  }

.brand-profile__designer-name {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

.brand-profile__designer-profile-photo {
    display: inline-block;
    height: 8rem;
    width: 8rem;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    --tw-grayscale: grayscale(100%)
  }

@media (min-width: 768px) {
    .brand-profile__designer-profile-photo {
      height: 10rem;
      width: 10rem;
    }
  }

.brand-profile__designer-profile-photo img {
      border-radius: 9999px;
    }

.brand-profile__brand-bio {
    margin-top: 1rem;
    border-top-width: 1px;
    border-color: var(--pageBGDark);
    padding-top: 1rem;
  }

@media (min-width: 768px) {
    .brand-profile__brand-bio {
      margin-top: 0px;
      width: 66.666667%;
      border-top-width: 0px;
      border-left-width: 1px;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
    }
  }

.brand-profile__brand-meta {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-items: end;
  }

.brand-profile__brand-meta-item {
    width: 50%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

@media (min-width: 768px) {
    .brand-profile__brand-meta-item {
      width: 33.333333%;
    }
  }

.brand-profile__brand-instagram {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-items: end;
  }

.brand-profile__brand-instagram-item {
    width: 100%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

@media (min-width: 768px) {
    .brand-profile__brand-instagram-item {
      width: 33.333333%;
    }
  }

.brand-profile__learn-more {
    margin-top: 2.5rem;
    margin-right: 2.5rem;
    display: inline-block;
    border-bottom-width: 1px;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

.brand-profile__learn-more:hover {
      color: var(--pageTheme);
    }

.brand-profile__learn-more:hover:after {
        content: '⟶';
        margin-left: 2rem;
      }

.brand-profile__learn-more:after {
      content: '⟶';
      margin-left: 0.75rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

.brand-profile__instagram {
    height: 1.5rem;
  }

.brand-cta {
  width: 100%
}

@media (min-width: 480px) {
  .brand-cta {
    max-width: 480px;
  }
}

@media (min-width: 768px) {
  .brand-cta {
    max-width: 768px;
  }
}

@media (min-width: 940px) {
  .brand-cta {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .brand-cta {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .brand-cta {
    max-width: 1600px;
  }
}

.brand-cta {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.brand-cta__container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
  }

@media (min-width: 768px) {
    .brand-cta__container {
      flex-direction: row;
    }
  }

.brand-cta__link {
    margin-top: 1.25rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }

@media (min-width: 768px) {
    .brand-cta__link {
      font-size: 3rem;
      line-height: 1;
    }
  }

.brand-cta__link-item {
    border-bottom-width: 1px;
  }

.brand-cta__link-item:hover {
      color: var(--pageTheme);
    }

.brand-cta__link-item:hover:after {
        content: '⟶';
        margin-left: 2rem;
      }

.brand-cta__link-item:after {
      content: '⟶';
      margin-left: 0.75rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

.brand-cta__instagrams {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-items: end;
  }

.brand-cta__instagram {
    margin-right: 1rem;
  }

.brand-cta__instagram:hover {
      color: var(--pageTheme);
    }

.quote {
  padding: 1.25rem;
}

.quote__wrapper {
    width: 100%;
  }

@media (min-width: 480px) {
    .quote__wrapper {
      max-width: 480px;
    }
  }

@media (min-width: 768px) {
    .quote__wrapper {
      max-width: 768px;
    }
  }

@media (min-width: 940px) {
    .quote__wrapper {
      max-width: 940px;
    }
  }

@media (min-width: 1200px) {
    .quote__wrapper {
      max-width: 1200px;
    }
  }

@media (min-width: 1600px) {
    .quote__wrapper {
      max-width: 1600px;
    }
  }

.quote__wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

.quote p {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 200;
    line-height: 1.625;
  }

@media (min-width: 768px) {
    .quote p {
      font-size: 1.875rem;
      line-height: 2.25rem;
      line-height: 1.625;
    }
  }

@media (min-width: 1200px) {
    .quote p {
      font-size: 3.75rem;
      line-height: 1;
      line-height: 1.625;
    }
  }

.paragraph {
  width: 100%
}

@media (min-width: 480px) {
  .paragraph {
    max-width: 480px;
  }
}

@media (min-width: 768px) {
  .paragraph {
    max-width: 768px;
  }
}

@media (min-width: 940px) {
  .paragraph {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .paragraph {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .paragraph {
    max-width: 1600px;
  }
}

.paragraph {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 940px) {
  .paragraph {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.paragraph__content > * {
    max-width: 42rem;
  }

.paragraph.has-block-angles {
    background-color: transparent;
  }

.paragraph h1, .paragraph .is-h1, .paragraph h2, .paragraph .is-h2, .paragraph h3, .paragraph .is-h3, .paragraph h4, .paragraph .is-h4 {
    max-width: 56rem;
  }

.paragraph .is-h1xl {
    max-width: none;
  }

.paragraph .rc__image {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    max-width: none;
  }

.sheet-of-paper {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  /* max-width: 800px;
    margin: auto;
    padding: 100px;
    background: white;
    box-shadow: 1px 3px 13px #0000005c; */
}

.sheet-of-paper__content {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    padding: 2.5rem;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

@media (min-width: 768px) {
    .sheet-of-paper__content {
      padding: 5rem;
    }
  }

.gallery__wrapper {
    max-width: 72rem;
    border-right-width: 1px;
    border-style: solid;
    border-color: var(--pageBGDark);
  }

.gallery__item {
    cursor: pointer;
  }

.gallery__primary-image {
    cursor: pointer;
  }

.gallery__thumb {
    width: 10rem;
  }

.gallery__thumbnails {
    /* @apply overflow-hidden w-full; */
    display: flex;
  }

.hubspot-form {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
  max-width: 56rem;
}

/* put your shameful styles here */

/* HULL demo theme styles: */

----- Mixins ----- .is-hashed {
  background-image: repeating-linear-gradient(
    -45deg,
    hsla(var(--pageText-hsl), 0.5) 0,
    hsla(var(--pageText-hsl), 0.5) 1px,
    transparent 0,
    transparent 50%
  );
  background-size: 8px 8px;
  background-repeat: repeat;
}

/* ----- Counters ----- */

/* @apply border rounded-full p-2 bg-pageBG text-pageText; */

.counter.is-small {
    padding: 0.25rem;
  }

.counter.is-inverted {
    background-color: transparent;
    color: var(--pageBG);
  }

.product--actions .counter {
    margin-right: 1rem;
  }

.counter--up, .counter--down {
    margin-top: -1px;
    margin-bottom: -1px;
    height: 2rem;
    width: 2rem;
    border-radius: 9999px;
    padding: 0.5rem;
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 300ms;
  }

.cart .counter--up, .cart .counter--down {
      margin-top: 0px;
      margin-bottom: 0px;
    }

.counter--up:hover, .counter--down:hover {
      background: hsla(var(--pageText-hsl), 0.2);
    }

.is-inverted .counter--up:hover, .is-inverted .counter--down:hover {
        background: hsla(var(--pageBG-hsl), 0.2);
      }

.counter--amount {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 0.75rem;
    line-height: 1rem;
  }

.cart .counter--amount {
      width: 2rem;
    }

.counter--amount input {
      padding: 0.25rem;
    }

.header--inner {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.mega-item--content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mega-item ul a, .mega-item ul button {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

.square-item {
  min-height: 400px;
}

.square-item--caption span {
  background-color: var(--pageHighlight);
  mix-blend-mode: normal;
  color: inherit;
  padding: 0.2rem;
}

.promo-bar {
  background-color: var(--pageBGDark);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.collection-tiled-images--details {
    min-height: 400px;
  }

.collection {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.collection--grid {
    margin: 0px;
    grid-gap: 2rem;
    gap: 2rem;
  }

.collection .product-card {
    margin: 0px;
    padding-bottom: 0px;
  }

.product-card {
  margin: 0px;
  padding-bottom: 0px;
}

.product-card--link {
    font-size: 1rem;
    line-height: 1.5rem;
  }

.collection--pagination {
  margin-top: 2rem;
}

/* purgecss start ignore */

@font-face {
  font-family: 'lg';

  src: url(/_next/static/media/lg.955a4bcf.woff2) format("woff2"), url(/_next/static/media/lg.dc565ab5.ttf) format("truetype"), url(/_next/static/media/lg.c950f0b5.woff) format("woff"), url(/_next/static/media/lg.a5ca0178.svg#lg) format("svg");

  font-weight: normal;

  font-style: normal;

  font-display: block;
}

.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lg' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-container {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.lg-next,
.lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
}

.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}

.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}

.lg-single-item .lg-next, .lg-single-item
.lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}

.lg-next:before {
  content: '\e095';
}

.lg-prev {
  left: 20px;
}

.lg-prev:after {
  content: '\e094';
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }

  50% {
    left: -30px;
  }

  100% {
    left: 0;
  }
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }

  50% {
    left: -30px;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }

  50% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }

  50% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: '\e070';
}

.lg-toolbar .lg-maximize {
  font-size: 22px;
}

.lg-toolbar .lg-maximize:after {
  content: '\e90a';
}

.lg-toolbar .lg-download:after {
  content: '\e0f2';
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  transition: opacity 0.2s ease-out 0s;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

.lg-sub-html a {
  color: inherit;
}

.lg-sub-html a:hover {
  text-decoration: underline;
}

.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}

.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-error-msg {
  font-size: 14px;
  color: #999;
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
  opacity: 0;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-group:after {
  content: '';
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
  opacity: 1;
}

.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image {
  opacity: 1;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  transition-duration: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0s;
  white-space: nowrap;
}

.lg-outer .lg-item {
  will-change: transform, opacity;
  display: none !important;
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url(/_next/static/media/loading.49ca460c.gif) no-repeat scroll center center transparent;
}

.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}

.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}

.lg-outer.lg-hide-download .lg-download {
  opacity: 0.75;
  pointer-events: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  transition: opacity 333ms ease-in 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}

.lg-inline .lg-backdrop {
  z-index: 1;
}

.lg-inline .lg-outer {
  z-index: 2;
}

.lg-inline .lg-maximize:after {
  content: '\e909';
}

.lg-components {
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  transition-duration: 0ms !important;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s;
}

.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  transition: transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image,
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img {
  transform: scale3d(1, 1, 1);
  will-change: opacity, transform;
  transition: transform 0.5s cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s, opacity 0.15s !important;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-icon.lg-zoom-in:after {
  content: '\e311';
}

.lg-icon.lg-actual-size {
  font-size: 20px;
}

.lg-icon.lg-actual-size:after {
  content: '\e033';
}

.lg-icon.lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

.lg-icon.lg-zoom-out:after {
  content: '\e312';
}

.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer[data-lg-slide-type='video'] .lg-zoom-in,
.lg-outer[data-lg-slide-type='video'] .lg-actual-size,
.lg-outer[data-lg-slide-type='video'] .lg-zoom-out, .lg-outer[data-lg-slide-type='iframe'] .lg-zoom-in,
.lg-outer[data-lg-slide-type='iframe'] .lg-actual-size,
.lg-outer[data-lg-slide-type='iframe'] .lg-zoom-out, .lg-outer.lg-first-slide-loading .lg-zoom-in,
.lg-outer.lg-first-slide-loading .lg-actual-size,
.lg-outer.lg-first-slide-loading .lg-zoom-out {
  opacity: 0.75;
  pointer-events: none;
}

/* purgecss end ignore */

