.retailers-list {
  @apply relative p-4 sm:p-8;

  &--list {
    @apply flex flex-wrap -mx-4 overflow-hidden;
  }

  &--retailer {
    @apply my-16 px-10 w-full overflow-hidden sm:w-1/2 md:w-1/3 lg:w-1/4;
  }
  &--name {
    @apply font-bold text-base;
  }
  &--address {
    @apply mt-2;
  }
  &--website {
    @apply mt-2 text-sm;
  }
}

.featured-retailers {
  @apply relative p-4 sm:p-8;

  &--list {
    @apply flex flex-wrap -mx-4 overflow-hidden justify-center items-center;
  }

  &--retailer {
    @apply my-16 px-10 w-full overflow-hidden text-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5;
    max-width: 300px;
  }
  &--name {
    @apply font-bold text-base;
  }
  &--address {
    @apply mt-2;
  }
  &--website {
    @apply mt-2 text-sm;
  }
}
