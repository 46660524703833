.drawer {
  @apply fixed top-0 right-0 bottom-0 w-full max-w-2xl z-90 bg-pageText text-pageBG;

  &--backdrop {
    @apply block fixed inset-0 bg-black bg-opacity-80 z-80;
  }

  &--inner {
    @apply flex flex-col relative h-full;
  }

  &--header {
    @apply flex items-center relative p-4;
  }

  &--close {
    /* Custom Close Button styles */
  }

  &--title {
    @apply mr-auto;
  }

  &--content {
    @apply flex-1 overflow-y-scroll p-4;
  }
}