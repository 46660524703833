.collection {
  @apply overflow-hidden;

  &--grid {
    @apply grid grid-cols-1 md:grid-cols-3 -m-px;
  }

  &--pagination {
    @apply relative p-4 sm:py-8 bg-pageBG border-t text-center;
  }
}

.collection-count {
  @apply inline-block relative ml-2 leading-none;
  vertical-align: super;
  font-size: 0.5em;
}

.product-card {
  @apply flex flex-col relative;

  .collection &,
  .marquee--product & {
    @apply pb-8;
    margin: -0.5px;
  }

  &.is-inline {
    @apply my-4;
  }

  &--out-of-stock-label {
    @apply absolute top-3 left-3 z-20;
  }

  &.out-of-stock {
    .product-card--thumb {
      opacity: 0.5;
    }
  }

  &--thumb {
    @apply z-0;
  }

  &--photo {
    @apply relative overflow-hidden h-96 sm:h-screen;

    @media (--md) {
      height: 27vw;
    }

    .is-hover {
      @apply absolute inset-0 z-10 opacity-0 invisible transition-all;

      .product-card:hover & {
        @apply opacity-100 visible;
      }
    }
  }

  &--gallery {
    @apply relative;

    .carousel {
      &--hud {
        @apply z-30;
      }
    }
  }

  &--details {
    @apply text-center;

    .product-card--visuals + & {
      @apply mt-6;

      .is-inline & {
        @apply mt-4;
      }
    }
  }

  &--title {
    @apply m-0 !important;

    .is-featured & {
      @apply text-base;
    }

    .is-inline & {
      @apply text-2xl;
    }

    + .price {
      @apply mt-2;
    }
  }

  &--link {
    @apply block no-underline text-current;

    &::after {
      @apply block absolute inset-0 z-20;
      content: '';
    }
  }

  &--option {
    @apply relative z-30 mt-4;

    .option {
      @apply m-0 p-0 border-0;

      &--values {
        @apply mx-auto;
      }
    }
  }
}

.price {
  &--previous {
    @apply line-through pr-3;
  }

  &--current {
    @apply sm:text-base sm:leading-normal;
  }

  &--discount {
    @apply ml-2 p-1 rounded-sm bg-pageText text-pageBG text-xxs leading-tight tracking-widest font-semibold uppercase;
  }
}

.product-featured {
  @apply flex flex-col relative;

  h2 {
    @apply mt-4 text-base font-semibold text-center transition-opacity duration-200;

    .product-featured:hover & {
      @apply opacity-100 visible;
    }
  }

  &:hover {
    h2 {
      @apply opacity-40;
    }
  }
}
