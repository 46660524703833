.blog {
  &--date {
    @apply text-xs;
  }

  &--gallery {
    @apply mt-8 max-w-7xl;
  }

  &--content {
    @apply mt-6;

    p {
      @apply max-w-prose;
    }
    figure {
      @apply max-w-4xl;
    }
  }
}

.blog-preview {
  &__post {
    @apply relative grid lg:grid-cols-12 grid-flow-row-dense;
  }

  &__date {
    @apply text-xs;
  }

  &__content {
    @apply p-8 lg:flex lg:items-end lg:col-span-6;
  }

  &__content-wrapper {
    @apply w-2/3;
  }

  &__page-title {
    @apply p-8;
  }

  &__description {
    @apply mt-5;

    p {
      @apply max-w-prose;
    }

    figure {
      @apply max-w-4xl;
    }
  }
}
