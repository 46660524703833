@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Century Gothic';
  src: url('/fonts/century-gothic/CenturyGothic.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Century Gothic';
  src: url('/fonts/century-gothic/CenturyGothic-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Century Gothic';
  src: url('/fonts/century-gothic/CenturyGothic-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
